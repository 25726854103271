// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import vuetify from './plugins/vuetify';
// import vSelect from "vue-select";
// import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
// import AxiosPlugin from 'vue-axios-cors';
// Vue.config.productionTip = false
import VTooltip from 'v-tooltip'

import VueGoodTablePlugin from 'vue-good-table';


// import the styles 
// import 'vue-good-table/dist/vue-good-table.css'

Vue.use(VueGoodTablePlugin);
export const eventBus = new Vue();


// Vue.use(Antd);
Vue.use(VTooltip)



// Vue.http.headers.common['Access-Control-Allow-Origin'] = true;
// // Vue.http.headers.common['Access-Control-Allow-Origin'] = '*';
// Vue.http.options.emulateJSON = true;
// Vue.http.options.credentials = true;
// Vue.http.options.emulateHTTP = true;
// Vue.use(AxiosPlugin);

// require('./assets/styles/vendor.css');
// require('./assets/styles/main.css');
// require('./assets/scripts/vendor/modernizr.js');

// Vue.component("v-select", vSelect);
// import { Row, Column } from 'vue-grid-responsive';

// Vue.component('row', Row);
// Vue.component('column', Column);

new Vue({
	router,
	store,
	// vuetify,
	// components: {
	// 	ClipLoader,
	// },
	// vSelect,
	render: (h) => h(App),
}).$mount('#app');
