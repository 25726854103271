import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '../views/auth/Login.vue';
import Registration from '../views/auth/Registration.vue';
import Recovery from '../views/auth/Recovery.vue';

import Policy from '../views/Policy.vue';
import Proposals from '../views/Proposals.vue';
import Contacts from '../views/Contacts.vue';

import Main from '../views/Main.vue';
import SelectData from '../views/SelectData.vue';
import Report from '../views/Report.vue';
import Settings from '../views/Settings.vue';
import Reference from '../views/Reference.vue';



Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',

  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
    },
    {
      path: '/registration',
      name: 'registration',
      component: Registration,
    },
    {
      path: '/recovery',
      name: 'recovery',
      component: Recovery,
    },
    {
      path: '/main',
      name: 'main',
      component: Main,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/select-data',
      name: 'selectData',
      component: SelectData,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/report',
      name: 'report',
      component: Report,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/reference',
      name: 'reference',
      component: Reference,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/policy',
      name: 'policy',
      component: Policy,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/proposals',
      name: 'proposals',
      component: Proposals,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: Contacts,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  /* eslint-disable */
  if (!to.matched.length) {
    next('/');
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next('/');
      router.app.$store.dispatch('setLoggedIn', false);
    }
  }
  if (to.matched.some((record) => record.name === 'login')) {
    if (localStorage.getItem('token')) {
      next({ name: 'main' });
    }
  }
});

export default router;
