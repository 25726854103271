<template>
  <div class="container">
    <div class="settings-page">
      <display-settings></display-settings>

      <div class="settings-report">
        <div class="row">
          <h1>Настройки отчета</h1>
        </div>

        <div class="row">
          <div class="col-md-3 select-box">
            <div class="select-box-item">
              <div class="select-header">
                <h2>{{ viewSettings.reportCurrency.title }}</h2>
              </div>

              <select
                v-model="viewSettings.reportCurrency.selectedCurrencyItem"
              >
                <option
                  v-for="item in viewSettings.reportCurrency.currencyItems"
                  :key="item.id"
                  :value="{
                    id: item.id,
                    title: item.title,
                    rate: item.rate,
                  }"
                >
                  {{ item.title }}
                </option>
              </select>

              <div class="space-between">
                <span>Курс валюты</span>
                <input
                  type="text"
                  disabled
                  style="width: 116px"
                  v-model="
                    viewSettings.reportCurrency.selectedCurrencyItem.rate
                  "
                />
              </div>
            </div>
          </div>

          <div class="col-md-3 select-box">
            <div class="select-box-item">
              <div class="select-header">
                <h2>{{ viewSettings.incomeTax.title }}</h2>
              </div>

              <template v-for="item in viewSettings.incomeTax.items">
                <div v-bind:key="item.title">
                  <input
                    style="width: initial"
                    type="radio"
                    v-bind:key="item.id"
                    :value="{
                      id: item.id,
                      title: item.title,
                    }"
                    :id="item.id"
                    v-model="
                      viewSettings.incomeTax.selectedDistrictСoefficientItem
                    "
                  />

                  <label :for="item.id">
                    {{ item.title }}
                  </label>

                  <div class="space-between">
                    <span style="margin-left: 28px" v-if="item.subtitle">
                      {{ item.subtitle }}
                    </span>

                    <input
                      type="text"
                      style="width: 116px"
                      disabled
                      v-if="item.percents"
                      v-model="item.percents"
                    />
                  </div>
                </div>
              </template>
            </div>

            <div class="select-box-item">
              <div class="select-header">
                <h2>{{ viewSettings.districtСoefficients.title }}</h2>
              </div>

              <ul>
                <li
                  v-for="item in viewSettings.districtСoefficients.items"
                  v-bind:key="item.id"
                >
                  <input :id="item.id" type="checkbox" v-model="item.value" />
                  <label :for="item.id">{{ item.title }}</label>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-3 select-box">
            <div class="select-box-item">
              <div class="select-header">
                <h2>{{ viewSettings.dataIndexing.title }}</h2>
              </div>

              <ul>
                <li>
                  <input
                    type="checkbox"
                    :id="viewSettings.dataIndexing.option.id"
                    v-model="viewSettings.dataIndexing.option.value"
                  />
                  <label :for="viewSettings.dataIndexing.option.id">
                    {{ viewSettings.dataIndexing.option.title }}
                  </label>
                </li>
              </ul>

              <div v-if="viewSettings.dataIndexing.option.value === true">
                <template
                  v-for="item in viewSettings.dataIndexing.option.options.items"
                >
                  <div class="template" v-bind:key="item.title">
                    <div class="template-item">
                      <input
                        :id="item.id"
                        style="width: initial"
                        type="radio"
                        v-bind:key="item.id"
                        :value="item"
                        v-model="
                          viewSettings.dataIndexing.option.options
                            .selectedOptionsItem
                        "
                      />

                      <label style="width: 200px" :for="item.id">
                        {{ item.title }}
                      </label>

                      <div class="percent-input">
                        <input
                          class="num-percent"
                          v-if="item.variable === 'percent'"
                          min="1"
                          max="100"
                          style="width: 90px"
                          type="number"
                          v-bind:key="item.value"
                          v-model="item.value"
                        />
                        <span>%</span>
                      </div>
                    </div>

                    <div
                      v-if="
                        item.options &&
                        viewSettings.dataIndexing.option.options
                          .selectedOptionsItem == item
                      "
                    >
                      <template v-for="itemElement in item.options">
                        <div
                          class="template-option-item"
                          v-bind:key="itemElement.id"
                        >
                          <label
                            :for="itemElement.id"
                            style="padding-left: 56px; display: inline-block"
                          >
                            {{ itemElement.title }}
                          </label>

                          <input
                            :id="itemElement.id"
                            v-if="itemElement.variable == 'number'"
                            min="1"
                            max="100"
                            style="width: 90px"
                            type="number"
                            v-bind:key="itemElement.value"
                            v-model="itemElement.value"
                          />

                          <select
                            v-if="itemElement.selectedPeriod"
                            style="width: 133px; display: inline-block"
                            v-model="itemElement.selectedPeriod"
                          >
                            <option
                              v-for="period in itemElement.periods"
                              :key="period.id"
                              :value="{
                                id: period.id,
                                title: period.title,
                              }"
                            >
                              {{ period.title }}
                            </option>
                          </select>
                        </div>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="col-md-3 select-box">
            <div class="select-box-item">
              <div class="select-header">
                <h2>{{ viewSettings.additionalSettings.title }}</h2>
                <span>{{ viewSettings.additionalSettings.secondTitle }}</span>
              </div>

              <template v-for="item in viewSettings.additionalSettings.items">
                <div v-bind:key="item.title">
                  <input
                    style="width: initial"
                    type="radio"
                    v-bind:key="item.id"
                    :value="{
                      id: item.id,
                      title: item.title,
                    }"
                    :id="item.id"
                    v-model="
                      viewSettings.additionalSettings
                        .selectedDistrictСoefficientItem
                    "
                  />

                  <label :for="item.id">{{ item.title }}</label>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="row space-around">
          <button @click="saveNOpenReport()" class="dark-blue">
            Сохранить и перейти к отчету
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DisplaySettings from '@/components/DisplaySettings'
import router from "./../router";
import { mapActions } from 'vuex';


export default {
  name: "Settings",
  components: {
    'display-settings': DisplaySettings
  },

  data: () => ({
    viewSettings: {
      reportCurrency: {
        title: "Валюта отчета",
        selectedCurrencyItem: {
          id: "g2",
          title: "Доллар",
          rate: "28,3534 руб.",
        },
        currencyItems: [
          {
            id: "gcm1",
            title: "Гривна",
            rate: "18,3534 руб.",
          },
          {
            id: "gm2",
            title: "Рубль",
            rate: "1 руб.",
          },
          {
            id: "g2",
            title: "Доллар",
            rate: "28,3534 руб.",
          },
          {
            id: "g3",
            title: "Евро",
            rate: "33,3534 руб.",
          },
        ],
      },
      incomeTax: {
        title: "Подоходный налог",
        items: [
          {
            id: "hi1",
            title: "Включен (Gross)",
            subtitle: "Ставка налога",
            percents: "13%",
          },
          {
            id: "hi565",
            title: "Исключен (Net)",
          },
        ],
        selectedDistrictСoefficientItem: {
          id: "hi565",
          title: "Исключен (Net)",
        },
      },
      districtСoefficients: {
        title: "Районные Коэффициенты",
        items: [
          {
            id: "i3231",
            title: "РКСН включены",
            value: true,
          },
        ],
      },
      dataIndexing: {
        title: "Индексация данных",
        option: {
          id: "ki3231111",
          title: "Данные проиндексированы",
          value: true,
          options: {
            selectedOptionsItem: {
              id: "lz1",
              title: "Абсолютный уровень индексации в %",
              value: 10,
              variable: "percent",
            },
            items: [
              {
                id: "lz1",
                title: "Абсолютный уровень индексации в %",
                value: 10,
                variable: "percent",
              },
              {
                id: "lz2",
                title: "Годовой уровень индексации в %",
                value: 20,
                variable: "percent",

                options: [
                  {
                    id: "mz3",
                    title: "Прошло месяцев с даты обзора",
                    value: 30,
                    variable: "number",
                  },
                  {
                    id: "mz4",
                    title: "Перилд сбора данных",
                    selectedPeriod: {
                      id: "nm1",
                      title: "Январь 2020",
                    },
                    periods: [
                      {
                        id: "nm1",
                        title: "Январь 2020",
                      },
                      {
                        id: "nm2",
                        title: "Фувраль 2020",
                      },
                      {
                        id: "nm3",
                        title: "Март 2020",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
      additionalSettings: {
        title: "Дополнительные Настройки",
        secondTitle: "Сравнение данных компании:",
        items: [
          {
            id: "zi1",
            title: "Медиана",
          },
          {
            id: "zi565",
            title: "Среднее арифметическое",
          },
        ],
        selectedDistrictСoefficientItem: {
          id: "zi565",
          title: "Среднее арифметическое",
        },
      },
    },
  }),
  methods: {
    ...mapActions(['GET_REPORT_ELEMENTS']),

    async select(obj, action) {
      console.log(obj, action);
      if (action === "select") {
        obj["items"].forEach(function (item) {
          item.value = true;
        });
      }
      if (action === "clear") {
        obj["items"].forEach(function (item) {
          item.value = false;
        });
      }
      // await this.reRender();
    },
    // async getReportElements() {
    //   // GET /api/report-elements

    //   await HttpService.methods
    //     .get(`/api/report-elements`, {})
    //     .then((response) => {
    //       console.log("!!!!!!!! report-elements", response.data.reportElement.data);
    //       // if (response.data) {
    //       //   this.periods = response.data.period.data;
    //       // }
    //       // this.convertPeriods(this.periods);
    //       // this.selectedPeriodsCount(this.periods);
    //     })
    //     .catch((error) => {
    //       // console.log('!!!!!!!! error', error)
    //       // ErrorService.methods.showError(error,'Login(login)')
    //       if (error.response.data.error && error.response.statusText) {
    //         console.log(
    //           `${error.response.statusText}: ${error.response.data.error} `
    //         );
    //       }
    //     });
    // },

    saveNOpenReport() {
      console.log(this.viewSettings);
      router
        .push({
          path: `/dashboard/report`,
          // query: {
          // 	date: JSON.stringify({ plan: 'private' }),
          // },
          // // props: { date: JSON.stringify({ plan: 'private' }) },
          // props: {
          // 	date: { type: JSON.stringify({ plan: 'private' }) },
          // },
          // params: { test: 'test' },
        })
        .catch((err) => {
          err;
        });
    },
    // async reRender() {
    //   this.$forceUpdate();
    // },
  },
  created() {
    // this.GET_REPORT_ELEMENTS()
    // console.log("__________________________________________---");
    // this.getReportElements();
    // console.log("think about redirect to dashboard If token not empty")
  },
};
</script>

<style scoped lang="less">
.settings-page {
  margin: 30px 0;
}
.container {
  max-width: 1100px;
}

#settings {
  // width: 1280px;
  padding: 0 126px;
  h1 {
    color: #405a91;
    font-size: 26px;
  }

  .container {
    display: flex;
    justify-content: center;
  }

  .percent-input {
    position: relative;
    display: inline-block;
    span {
      position: absolute;
      top: 8px;
      right: 4px;
      line-height: 100%;
      vertical-align: middle;
    }
    .num-percent {
      width: 30px;
      text-align: right;
      padding-right: 15px;
    }
  }

  .template-option-item {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .template-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //margin-left: 26px;
    input[type="checkbox"]:checked + label:before,
    input[type="checkbox"]:not(:checked) + label:before,
    input[type="radio"]:checked + label:before,
    input[type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0px;
      //top: 0px;
      width: 18px;
      height: 18px;
      border: 1px solid #20b1dd;
      background-color: #ffffff;
      transform: translate(0, 50%);
      //top: 50%;
    }

    input[type="checkbox"]:checked + label:after,
    input[type="checkbox"]:not(:checked) + label:after {
      left: 4px;
      //top: 5px;
      transform: translate(0, 50%);
      width: 10px;
      height: 5px;
      border-radius: 1px;
      border-left: 2px solid #20b1dd;
      border-bottom: 2px solid #20b1dd;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    input[type="radio"]:checked + label:after,
    input[type="radio"]:not(:checked) + label:after {
      left: 4px;
      //top: 4px;
      transform: translate(0, 50%);
      top: 8px;

      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: #20b1dd;
    }
  }

  .settings-report {
    border: 1px solid #dde2e6;
    border-radius: 5px;
    // background-color: #f2fcfe;
    background-color: #ffffff;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.18);
    margin: 22px 0;
    // margin: 22px 40px 0 40px;
    // margin: 35px 7px 13px;

    padding: 20px 45px;
    h2 {
      color: #323c56;
      font-size: 20px;
    }
    .col-md-3 {
      padding: 12px 24px 12px 0;
    }

    input[type="number"],
    input[type="text"] {
      text-align: right;
      color: #666768;
      width: initial;
      height: 32px;
      padding: 6px 10px;
      width: 100%;
      border-radius: 6px 6px;
      border: 1px solid #dfeaf1 !important;
      background-color: #f2fcfe !important;
      color: #626367 !important;
      position: relative;
    }

    label {
      color: #666768;
    }
    .select-box {
      span {
        color: #666768;
      }
      ul {
        li {
          display: flex;
          margin-bottom: 8px;
          //input{
          //  text-align: right;
          //  width: initial;
          //}
          label {
            // margin-left: 13px;
          }
        }
      }
      &-item {
        &:not(:last-child) {
          margin-bottom: 23px;
        }
        .select-header {
          padding-top: 7px;
          padding-bottom: 10px;
          border-top: 1px solid #e4eef0;
          .option {
            margin-left: 24px;
          }
        }
        .payment {
          &-list {
            list-style-position: inside;
            column-count: 3;
            //height: 200px;
          }
        }
      }
    }
  }
}
</style>
