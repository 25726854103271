export const blockVisibility = [
  // {
  //   orderNumber: 1,
  //   id: 'salaryData',
  //   displayName: 'Данные компании',
  // },
  {
    orderNumber: 2,
    id: 'regions',
    isTableData: false,
    displayName: 'Описание областей',
  },
  {
    orderNumber: 3,
    id: 'positionLevels',
    isTableData: false,
    displayName: 'Описание уровней',
  },
  {
    orderNumber: 4,
    reportOrderNumber: 6,
    id: 'quantities',
    isTableData: true,
    displayName: 'Количество значений',
    displayReportName:{
      company:  "Кол-во компаний",
      employee: "Кол-во сотрудников"
    }
  },
  {
    orderNumber: 5,
    reportOrderNumber: 7,
    id: 'yearIncomePct',
    isTableData: true,
    displayName: '% вознаграждения',
    displayReportName: "% от дохода в год"
  },
  {
    orderNumber: 6,
    id: 'employeeNumber',
    isTableData: false,
    displayName: 'Количество подчинённых',
    displayReportName: 'Подчинённых сотрудников',
  }
];
