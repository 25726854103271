<template>
  <section class="section" :class="[isDisabled ? 'disabled' : null]">
    <div class="section__top">
      <div class="grid">
        <div class="grid-item grid-item-6">
          <h2 class="section-title">
            <span class="section-title__number">{{ content.order }}.</span>{{ content.title }}
          </h2>
        </div>
        <div class="grid-item grid-item-6">
          <button class="main-btn mr-30" @click="isOpen = true">
            Выбрать
          </button>
          <button class="select-all-btn" @click="selectAndSaveAllData">
            Выбрать всё
          </button>
        </div>
        <div class="grid-item grid-item-12 saved-data-wrap">
          <div class="saved-data">
            <span v-if="!savedDataNames.length" class="not-saved-data__title">
              Выбор не сделан
            </span>
            <span v-if="savedDataNames.length" class="saved-data__count">
              Выбрано&nbsp;{{ savedDataNames.length }}:&nbsp;
            </span>
            <span v-if="savedDataNames.length" class="saved-data__list">{{ savedDataNames.join(', ') }}</span>
          </div>        
        </div>
      </div>
    </div>

    <selection-section 
      v-if="isOpen"
      :data='data'
      :changeIsOpen='changeIsOpen'
      :type='type'
    />
  </section>
</template>


<script>
import content from '../../content/SelectDataContent.js';
import { mapActions, mapGetters } from 'vuex';
import SelectionSection from '@/components/SelectData/SelectionSection.vue';


export default {
  name: 'SelectDataOption',
  props: ['data', 'type'],
  components: {
    'selection-section': SelectionSection,
  },
  data() {
    return {
      content: content[this.type],
      isOpen: false,
      foundData: null,
      selectedData: this.type === 'period' ? {} : [],

      disabled: true
    };
  },
  computed: {
    ...mapGetters(['dataForReport']),


    isDisabled() {
      let disabled = true

      if (this.type === 'cities') {
        disabled = Object.values(this.data).every(value => {
          return value.length === 0
        })
      }
      if (this.type !== 'cities' && this.data.length !== 0) {
        disabled = false
      }

      return disabled
    },


    savedDataNames() {
      if (this.dataForReport && this.dataForReport[this.type]) {
        return this.dataForReport[this.type].map((item) => item[this.content.nameKey])
      }
      return []
    },
  },
  methods: {
    ...mapActions(['setDataForReport']),

    changeIsOpen(v) {
      this.isOpen = v
    },
    selectAndSaveAllData() {
      if (this.type === 'specializations') {
        this.selectedData = this.data.map(item => {
          if (item.children) {
            return [item, item.children]
          } else {
            return item
          }
        }).flat(Infinity)
      }else if (this.type === 'cities') {
        this.selectedData = this.data.cities
      } else {
        this.selectedData = this.data;
      }
      this.setDataForReport({ key: this.type, data: this.selectedData });

    },

  },

};
</script>
<style scoped src='../../assets/styles/components/SelectData.css'></style>
