<template>
  <div class="section__selecting">
    <button class="close-btn" @click="changeIsOpen(false)">
      &#10761;
    </button>
    <div class="grid">
      <div class="grid-item grid-item-12">
        <select-cities-list
          v-if="type === 'cities'"
          :changeSelected="changeSelected"
          :data="finalData"
          :content="content"
          :selected="selectedData"
        ></select-cities-list>

        <checkbox-list
          v-if="type !== 'cities'"
          :changeSelected="changeSelected"
          :data="finalData"
          :content="content"
          :selected="selectedData"
          :type="type"
        ></checkbox-list>
      </div>

      <div class="grid-item grid-item-12">
        <search :handleSearch="handleSearch" />
        <div class="selected-data__list">
          <p class="selected-data__count">Выбрано {{ selectedData.length }}:</p>
          <span
            class="selected-item"
            v-for="data in selectedData"
            :key="data.id"
          >
            {{ data[content.nameKey] }}
            <span
              class="selected-item__close"
              type="close"
              @click="removeItem(data.id)"
              >&#10761;</span
            >
          </span>
        </div>
      </div>
      <div class="grid-item grid-item-24">
        <div class="selecting-controls">
          <div class="selecting-controls__left">
            <button class="clear-all-btn" @click="clearAllData">
              Очистить
            </button>
            <button class="select-all-btn" @click="selectAllData">
              Выбрать всё
            </button>
          </div>
          <div class="selecting-controls__right">
            <button class="secondary-btn" @click="saveSelectedData">
              Сохранить
            </button>
            <button class="outlined-btn" @click="cancelSelectedData">
              Отменить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import content from '../../content/SelectDataContent.js';
import { mapActions, mapGetters } from 'vuex';
import CheckboxList from '@/components/SelectData/CheckboxList.vue';
import SelectCitiesList from '@/components/SelectData/SelectCitiesList.vue';
import Search from '@/components/SelectData/Search.vue';

export default {
  name: 'SelectionSection',
  props: ['data', 'type', 'isOpen', 'changeIsOpen'],
  components: {
    'checkbox-list': CheckboxList,
    'select-cities-list': SelectCitiesList,
    search: Search,
  },

  data() {
    return {
      content: content[this.type],
      foundData: null,
      selectedData: this.type === 'period' ? {} : [],

      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters(['dataForReport']),

    finalData() {
      return this.foundData || this.data || [];
    },
  },
  methods: {
    ...mapActions(['setDataForReport']),

    handleSearch(v) {
      const query = v.trim().replace(/[\s]{2,}/g, () => ' ').toLowerCase();
      //eslint-disable-next-line no-unused-vars
      let initialData = [];
      initialData = JSON.parse(JSON.stringify(this.data));
      if (this.type === 'cities') {
        initialData = this.data.cities;
      }
      //eslint-disable-next-line no-unused-vars
      let foundData = [];

      if (this.type === 'specializations') {
        foundData = initialData.filter((specialization) => {
          if (
            `${specialization[this.content.nameKey]}`
              .toLowerCase()
              .includes(query)
          ) {
            return true;
          }
          if (specialization.children) {
            specialization.children = specialization.children.filter(
              (childSpecialization) => {
                return `${childSpecialization[this.content.nameKey]}`
                  .toLowerCase()
                  .includes(query);
              }
            );
            return specialization.children.length > 0;
          }
        });
      } else {
        foundData = initialData.filter((item) => {
          return `${item[this.content.nameKey]} ${item[
            this.content.shortNameKey
          ] || ''}`
            .toLowerCase()
            .includes(query);
        });
      }

      if (this.type === 'cities') {
        this.foundData = { ...this.data, cities: foundData };
      } else {
        this.foundData = foundData;
      }
    },
    changeSelected(v) {
      this.selectedData = v;
    },
    removeItem(itemId) {
      this.selectedData = this.selectedData.filter(
        (item) => item.id !== itemId
      );
    },
    selectAllData() {
      if (this.type === 'specializations') {
        this.selectedData = this.data
          .map((item) => {
            if (item.children) {
              return [item, item.children];
            } else {
              return item;
            }
          })
          .flat(Infinity);
      } else if (this.type === 'cities') {
        this.selectedData = this.data.cities;
      } else {
        this.selectedData = this.data;
      }

    },
    clearAllData() {
      this.selectedData = [];
    },
    saveSelectedData() {
      this.setDataForReport({ key: this.type, data: this.selectedData });
      this.changeIsOpen(false);
    },
    cancelSelectedData() {},
  },
  watch: {
    dataForReport: {
      handler(v) {
        if (v && v[this.type]) {
          this.selectedData = v[this.type];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped src="../../assets/styles/components/SelectionSection.css"></style>
