import HttpService from '../services/HttpService';



export default {
  setDataForReport: (context, payload) => {
    context.commit('setDataForReport', payload);
  },
  setLoggedIn: (context, payload) => {
    context.commit('setLoggedIn', payload);
  },
  setReportElements: (context, payload) => {
    context.commit('setReportElements', payload);
  },
  SET_SELECTED_REPORT_SETTINGS: (context, payload) => {
    context.commit('setSelectedReportSettings', payload);
  },
  GET_REPORT_ELEMENTS: (context) => {
    HttpService.methods
      .get(`/api/report-elements`)
      .then((response) => {
        const elements = response.data.reportElement.data.map((elem) => ({
          orderNumber: elem.id,
          id: elem.id,
          category: 'elements',
          displayName: elem.elementName,
          displayReportName: elem.elementReportName,
        }));
        context.commit('setReportElementsToSettings', elements);
        context.dispatch('GET_REPORT_TEMPLATES');
      })
      .catch((error) => {
        if (error.response.data.error && error.response.statusText) {
          console.log(`${error.response.statusText}: ${error.response.data.error}`);
        }
      });
  },
  GET_REPORT_TEMPLATES: (context) => {
    HttpService.methods
      .get(`/api/report-templates`)
      .then((response) => {
        const templates = response.data.reportTemplate.data;
        const defaultReportTemplate = templates.find((template) => template.isReadOnly);

        const reportSettingsCategories = Object.keys(context.state.reportSettings);

        const selectedReportSettings = reportSettingsCategories.reduce((settings, category) => {
          const settingsByCategory = defaultReportTemplate.displaySettings[category];

          if (settingsByCategory?.incomePct) {
            settingsByCategory.yearIncomePct = settingsByCategory.incomePct;
            delete settingsByCategory.incomePct;
          }

          const selectedSettingsByCategory = context.state.reportSettings[category].filter(
            (setting) => settingsByCategory?.[setting.id]
          );
          if (selectedSettingsByCategory) {
            return [...settings, ...selectedSettingsByCategory];
          }
          return settings;
        }, []);

        context.commit('setSelectedReportSettings', selectedReportSettings);
        context.commit('setReportTemplates', templates);
        context.commit('setReportDefaultTemplate', defaultReportTemplate);
      })
      .catch((error) => {
        if (error?.response?.data?.error && error?.response?.statusText) {
          console.log(`${error.response.statusText}: ${error.response.data.error}`);
        } else {
          console.log(error);
        }
      });
  },


  GET_REPORT: ({ commit, state }) => {
    const payload = {
      reportType: state.reportType.type,
      periodId: state.dataForReport.period.id,
      cityIds: state.dataForReport.cities.map((city) => city.id),
      companyGroupIds: state.dataForReport.companies.map((company) => company.id),
      specializationDescriptionIds: state.dataForReport.specializations.map((specialization) => specialization.id),
      positionLevelIds: state.dataForReport.positions.map((position) => position.id),
      // "qualificationIds": []
      // "reportElementIds": [<reportElementId-1>, ..., <reportElementId-N>],
      yearIncomePercentageBy: 'mean',
      dataTypes: ['company', 'employee'],
    };

    HttpService.methods
    .post(`/api/reports`, payload)
    .then((response) => {
      if(response.data.report.data.length) {
        commit('SET_REPORT', response.data.report.data);
      }
    })
    .catch((error) => {
      if (error?.response?.data?.error && error?.response?.statusText) {
        console.log(`${error.response.statusText}: ${error.response.data.error}`);
      } else {
        console.log(error);
      }
    });

  },

  SET_REPORT_TYPE: (context, payload) => {
    context.commit('SET_REPORT_TYPE', payload);
  },
  REMOVE_DATA_FOR_REPORT: (context, payload) => {
    context.commit('REMOVE_DATA_FOR_REPORT', payload);
  },
};
