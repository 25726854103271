<template>
  <div>
    <div class="tabs">
      <button
        class="tab"
        :class="[citiesSelectType === 'alphabet' ? 'current-tab' : null]"
        @click="citiesSelectType = 'alphabet'"
      >
        Города по алфавиту
      </button>
      <button
        class="tab"
        :class="[citiesSelectType.startsWith('region') ? 'current-tab' : null]"
        @click="citiesSelectType = 'regions'"
      >
        По регионам и областям
      </button>
    </div>
    <div class="alphabet" v-if="citiesSelectType == 'alphabet'">
      <span
        class="letter"
        @click="selectedLetter = letter"
        v-for="letter in citiesFullAlphabet"
        :key="letter"
        >{{ letter }}</span
      >
    </div>
    <div class="regions" v-if="citiesSelectType == 'regions'">
      <h3 class="regions-title">Регионы:</h3>
      <ul class="regions-list">
        <li
          class="regions-list-item"
          v-for="country in data.countries"
          :key="country.id"
        >
          <label class="custom-checkbox">
            <input
              type="checkbox"
              :value="country"
              @change="changeCountry(country, $event.target.checked)"
              v-model="selectedCountries"
            />
            <span>{{ country.countryName }} </span>
          </label>
        </li>
      </ul>
    </div>
    <div v-if="citiesSelectType == 'region'">
      <ul class="pagination">
        <li>
          <button
            class="pagination-item active"
            @click="citiesSelectType = 'regions'"
          >
            Регионы и области
          </button>
        </li>
        <li>
          <span class="pagination-item current">
            {{ selectedRegion && selectedRegion.regionName }}
          </span>
        </li>
      </ul>
    </div>

    <div class="data-selecting">
      <h3 v-if="citiesSelectType === 'alphabet'" class="selecting__title">
        Все города
      </h3>
      <h3
        v-if="citiesSelectType === 'regions' && !selectedRegion"
        class="selecting__title"
      >
        Области
      </h3>
      <h3
        v-if="citiesSelectType === 'region' && selectedRegion"
        class="selecting__title"
      >
        Города
      </h3>
      <div
        v-if="citiesSelectType === 'alphabet' || citiesSelectType === 'region'"
      >
        <h4 v-if="citiesByAlphabet.length == 0">Не найдено!</h4>
        <div v-for="(letter, i) in citiesAlphabet" :key="letter" class="grid">
          <div class="grid-item grid-item-2">
            <span class="city-letter">{{ letter }}</span>
          </div>
          <div class="grid-item grid-item-22">
            <ul class="grid cities-letter-group">
              <li
                v-for="city in citiesByAlphabet[i]"
                :key="city.id"
                class="grid-item grid-item-12"
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    v-model="selectedCities"
                    @change="changeCity(city, $event.target.checked)"
                    :value="city"
                  />
                  <span>{{ city.cityName }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="citiesSelectType === 'regions'">
        <div class="grid" v-for="letter in regionsAlphabet" :key="letter">
          <div class="grid-item grid-item-2">
            <span class="city-letter">{{ letter }}</span>
          </div>
          <div class="grid-item grid-item-22">
            <ul class="grid">
              <li
                class="grid-item grid-item-12"
                v-for="region in data.regions"
                :key="region.id"
              >
                <label class="custom-checkbox">
                  <input
                    type="checkbox"
                    v-model="selectedRegions"
                    @change="changeRegion(region, $event.target.checked)"
                    :value="region"
                  />
                  <span class="link" @click="selectedRegion = region">{{
                    region.regionName
                  }}</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: 'SelectCitiesList',
  props: ['data', 'selected', 'content', 'changeSelected'],

  data() {
    return {
      selectedData: [],
      openParent: null,

      citiesSelectType: 'alphabet',
      selectedLetter: 'А',

      selectedCities: [],
      selectedRegions: [],
      selectedRegion: null,
      selectedCountries: [],
    };
  },
  computed: {
    cities() {
      return this.citiesSelectType === 'region'
        ? this.data.cities.filter(
            (city) => city.regionId === this.selectedRegion.id
          )
        : this.data.cities;
    },
    regionsAlphabet() {
      return this.getAlphabet(this.data.regions, 'regionName');
    },
    citiesFullAlphabet() {
      return this.getAlphabet(this.cities, 'cityName');
    },
    citiesAlphabet() {
      const alphabet = this.citiesFullAlphabet;
      if (this.selectedLetter) {
        return alphabet.filter((letter) => letter === this.selectedLetter);
      } else {
        return alphabet;
      }
    },
    citiesByAlphabet() {
      return this.citiesAlphabet.map((letter) =>
        this.cities.filter((city) => {
          return city.cityName.slice(0, 1).toUpperCase() === letter;
        })
      );
    },
  },
  methods: {
    getAlphabet(arr, key) {
      return [
        ...new Set(arr.map((item) => item[key].slice(0, 1).toUpperCase())),
      ].sort();
    },
    changeCountry(selectedCountry, checked) {
      this.selectRegionsByCountry(selectedCountry, checked);
    },
    changeRegion(selectedRegion, checked) {
      this.selectCitiesByRegion(selectedRegion);
      if (this.selectedRegions.length === 0) {
        this.selectedCountries = [];
        return;
      }
      if (checked) {
        const allRegionsWithSameCountryId = this.data.regions.filter(
          (region) => region.countryId === selectedRegion.countryId
        ).length;
        const allSelectedRegionsWithSameCountryId = this.selectedRegions.filter(
          (region) => region.countryId === selectedRegion.countryId
        ).length;
        const targetCountry = this.data.countries.find(
          (country) => country.id === selectedRegion.countryId
        );
        if (
          allRegionsWithSameCountryId === allSelectedRegionsWithSameCountryId
        ) {
          this.selectedCountries.push(targetCountry);
        }
      } else {
        this.selectedCountries = this.selectedCountries.filter(
          (country) => country.id !== selectedRegion.countryId
        );
      }
    },
    changeCity(selectedCity, checked) {
      if (this.selectedCities.length === 0) {
        this.selectedRegions = [];
        this.selectedCountries = [];
        return;
      }
      if (checked) {
        const allCitiesWithSameRegionId = this.data.cities.filter(
          (city) => city.regionId === selectedCity.regionId
        ).length;
        const allSelectedCitiesWithSameRegionId = this.selectedCities.filter(
          (city) => city.regionId === selectedCity.regionId
        ).length;
        const targetRegion = this.data.regions.find(
          (region) => region.id === selectedCity.regionId
        );
        const targetCountry = this.data.countries.find(
          (country) => country.id === targetRegion.countryId
        );
        if (allCitiesWithSameRegionId === allSelectedCitiesWithSameRegionId) {
          this.selectedRegions.push(targetRegion);
          this.selectedCountries.push(targetCountry);
        }
      } else {
        this.selectedRegions = this.selectedRegions.filter(
          (region) => region.id !== selectedCity.regionId
        );
        this.selectedCountries = this.selectedCountries.filter(
          (country) => country.id !== selectedCity.countryId
        );
      }
    },
    selectCitiesByRegion(selectedRegion) {
 
      const exist = this.selectedRegions.find(
        (region) => region.id === selectedRegion.id
      );
      if (exist) {
        this.data.cities.forEach((city) => {
          if (city.regionId === selectedRegion.id) {
            const cityIdx = this.selectedCities.findIndex(
              (selectedCity) => selectedCity.id === city.id
            );
            if (cityIdx === -1) {
              this.selectedCities.push(city);
            }
          }
        });
      } else {
        this.selectedCities = this.selectedCities.filter(
          (selectedCity) => selectedCity.regionId !== selectedRegion.id
        );
      }
    },
    selectRegionsByCountry(selectedCountry, checked) {
      if (checked) {
        this.data.regions.forEach((region) => {
          if (region.countryId === selectedCountry.id) {
            const regionIdx = this.selectedRegions.findIndex(
              (selectedRegion) => selectedRegion.id === region.id
            );
            if (regionIdx === -1) {
              this.selectedRegions.push(region);
            }
          }
        });
      } else {
        this.selectedRegions = this.selectedRegions.filter(
          (selectedRegion) => selectedRegion.countryId !== selectedCountry.id
        );
      }
      this.data.regions.forEach((region) => {
        this.selectCitiesByRegion(region);
      });
    },
  },
  watch: {
    selected: {
      handler(v) {
        this.selectedCities = v;

        if (this.selectedCities.length) {
          this.selectedCities.forEach((city) => {
            this.changeCity(city, true);
          });
        } else{
          this.data.cities.forEach((city) => {
            this.changeCity(city, false);
          });
        }
      },
      immediate: true,
    },
    selectedCities(v) {
      this.changeSelected(v);
    },
    citiesFullAlphabet: {
      handler(v) {
        if (!this.selectedRegion) {
          this.selectedLetter = v[0];
        } else {
          this.selectedLetter = null;
        }
      },
      immediate: true,
    },
    selectedRegion(v) {
      if (v) {
        this.citiesSelectType = 'region';
      }
    },
    data(){
      this.citiesSelectType = 'alphabet'
    },
    citiesSelectType(v) {
      if (v === 'regions') {
        this.selectedRegion = null;
      }
      if (v === 'alphabet') {
        this.selectedRegion = null;
        this.selectedLetter = this.citiesFullAlphabet[0];
      }
    },
  },
};
</script>

<style scoped src="../../assets/styles/components/SelectData.css"></style>
