<template>
  <div class="selected-report-data">
    <div class="selected-type">
      <button 
        class="toggle-additional-btn" 
        @click="isOpenSelectedPeriodList = !isOpenSelectedPeriodList"
      >
        <span>Период</span>
        <i class="arrow-icon" :class="[isOpenSelectedPeriodList ? 'opened' : null]"></i>
      </button>

      <div v-if="isOpenSelectedPeriodList">  
        <ul class="selected-items__list">
          <li :title="period">
            {{period}}
          </li>
        </ul> 
      </div>
    </div>

    <div class="selected-type">
      <button 
        class="toggle-additional-btn" 
        @click="isOpenSelectedCitiesList = !isOpenSelectedCitiesList"
      >
        <span>Город</span>
        <i class="arrow-icon" :class="[isOpenSelectedCitiesList ? 'opened' : null]"></i>
      </button>

      <div v-if="isOpenSelectedCitiesList">
        <span class="selected-items-count">{{cities.length}}</span>

        <ul class="selected-items__list">
          <li v-for="city in cities" :key="city" :title="city">
            {{city}}
          </li>
        </ul>

        <div class="options">
          <button class="select-all-btn">Выбрать</button>
          <button class="select-all-btn">Выбрать всё</button>
        </div>      
      </div>
    </div>

    <div class="selected-type">
      <button 
        class="toggle-additional-btn" 
        @click="isOpenSelectedSpecializationsList = !isOpenSelectedSpecializationsList"
      >
        <span>Область сециализации</span>
        <i class="arrow-icon" :class="[isOpenSelectedSpecializationsList ? 'opened' : null]"></i>
      </button>

      <div v-if="isOpenSelectedSpecializationsList">
        <span class="selected-items-count">{{specializations.length}}</span>

        <ul class="selected-items__list">
          <li v-for="specialization in specializations" :key="specialization" :title="specialization">
            {{specialization}}
          </li>
        </ul>

        <div class="options">
          <button class="select-all-btn">Выбрать</button>
          <button class="select-all-btn">Выбрать всё</button>
        </div>      
      </div>
    </div>

    <div class="selected-type">
      <button 
        class="toggle-additional-btn" 
        @click="isOpenSelectedPositionsList = !isOpenSelectedPositionsList"
      >
        <span>Уровень должности</span>
        <i class="arrow-icon" :class="[isOpenSelectedPositionsList ? 'opened' : null]"></i>
      </button>

      <div v-if="isOpenSelectedPositionsList">
        <span class="selected-items-count">{{positions.length}}</span>

        <ul class="selected-items__list">
          <li v-for="position in positions" :key="position" :title="position">
            {{position}}
          </li>
        </ul>

        <div class="options">
          <button class="select-all-btn">Выбрать</button>
          <button class="select-all-btn">Выбрать всё</button>
        </div>      
      </div>
    </div>

    <div class="selected-type">
      <button 
        class="toggle-additional-btn" 
        @click="isOpenSelectedCompaniesList= !isOpenSelectedCompaniesList"
      >
        <span>Группа компаний</span>
        <i class="arrow-icon" :class="[isOpenSelectedCompaniesList ? 'opened' : null]"></i>
      </button>

      <div v-if="isOpenSelectedCompaniesList">
        <span class="selected-items-count">{{companies.length}}</span>

        <ul class="selected-items__list">
          <li v-for="company in companies" :key="company" :title="company">
            {{company}}
          </li>
        </ul>

        <div class="options">
          <button class="select-all-btn">Выбрать</button>
          <button class="select-all-btn">Выбрать всё</button>
        </div>      
      </div>
    </div>
  </div>
</template>


<script>
// import HttpService from '@/services/HttpService';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SelectedReportData',


  data() {
    return {
      isOpenSelectedPeriodList: false,
      isOpenSelectedCitiesList: false,
      isOpenSelectedSpecializationsList: false,
      isOpenSelectedPositionsList: false,
      isOpenSelectedCompaniesList: false,
      

    };
  },
  computed: {
    ...mapGetters(['dataForReport']),

    period() {
      if (!this.dataForReport.period.id) return 'Нет данных'

      const periodMonth = this.formatMonth(this.dataForReport.period.periodMonth) 
      return `${periodMonth} ${this.dataForReport.period.periodYear}`
    },

    cities() {
      if (!this.dataForReport.cities.length) return ['Нет данных']

      const citiesNames = this.dataForReport.cities.map(city => {
        return city.cityName
      })
      return citiesNames
    },
    specializations() {
      if (!this.dataForReport.specializations.length) return ['Нет данных']

      const specializationsNames = this.dataForReport.specializations.map(specialization => {
        return specialization.descriptionText
      })
      return specializationsNames
    },
    positions() {
      if (!this.dataForReport.positions.length) return ['Нет данных']

      const positionsNames = this.dataForReport.positions.map(position => {
        return position.levelName
      })
      return positionsNames
    },
    companies() {
      if (!this.dataForReport.companies.length) return ['Нет данных']

      const companiesNames = this.dataForReport.companies.map(company => {
        return company.compGroupName
      })
      return companiesNames
    }
  },
  methods: {
    ...mapActions([]),

    formatMonth (value) {
      const monthsLibrary = {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь',
      };
      return monthsLibrary[value];
    },



  },
  watch: {

  },
  created() {

  },
};
</script>
<style scoped>
li {
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.toggle-additional-btn {
  width: 100%;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.toggle-additional-btn > span {
  font-size: 18px;
  font-weight: 500;
}
.arrow-icon {
  border: solid #95a5b4;
  border-width: 0 1px 1px 0;
  padding: 4px;
  transform: rotate(45deg);
}
.opened {
  border-width: 1px 0 0 1px;
}

.selected-type {
  margin-bottom: 25px;
  border-bottom: 1px solid #dee8ed;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 15px;
}
.selected-items-count {
  font-size: 12px;
  color: #34b7e1;
  margin-bottom: 10px;
}
.selected-items__list {
  max-height: 209px;
  overflow-y: scroll;
  line-height: 1.5;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #ffff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
}

</style>
