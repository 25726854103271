<template>
  <header class="header">
    <div class="container">
      <div class="header-wrapper">
        <h2 class="logo">
          SalaryGuide
          <span>
            Online
          </span>
        </h2>

        <nav class="nav" v-if="isLoggedIn">
          <router-link class="nav__link" :to="{ name: 'policy' }">PolicyGuide</router-link>
          <router-link class="nav__link" :to="{ name: 'proposals' }">Ваши предложения</router-link>
          <router-link class="nav__link" :to="{ name: 'contacts' }">Контакты</router-link>
          <button @click="logout" class="nav__link">Выход</button>
        </nav>

        <img class="logo-img" src="../assets/images/logo.png" alt="logo" draggable="false" />
      </div>
    </div>
  </header>
</template>



<script>
import { mapActions, mapGetters } from 'vuex';
import router from '../router';

export default {
  name: 'Header',

  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapActions(['setLoggedIn']),
    logout() {
      localStorage.removeItem('token');
      this.setLoggedIn(false);
      router.push('/');
    },
  },
};
</script>

<style scoped src="../assets/styles/components/HeaderStyles.css"></style>
