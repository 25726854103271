<template>
  <div>
    <standart-report v-if="reportType.value === 'regular'" :report="report"></standart-report>
    <transposed-report v-if="reportType.value === 'trasposed'" :report="report"></transposed-report>
    <tabular-report v-if="reportType.value === 'tabular'" :report="report"></tabular-report>
  </div>
</template>



<script>
import StandartReport from '@/components/Report/StandartReport.vue'
import TransposedReport from '@/components/Report/TransposedReport.vue';
import TabularReport from '@/components/Report/TabularReport.vue';


export default {
  props: ['reportType', 'report'],
  components: {
    'standart-report': StandartReport,
    'transposed-report': TransposedReport,
    'tabular-report': TabularReport
  },
};
</script>

<style></style>
