<template>
  <div class="report-page">
    <div class="page-actions">
      <ul class="page-actions__list">
        <li class="page-actions__item" v-for="reportType in reportTypes" :key="reportType.id">
          <label class="custom-radiobutton">
            <input v-model="selectedReportType" :value="reportType" type="radio" />
            <span>
              {{ reportType.label }}
            </span>
          </label>
        </li>
        <li class="vertical-divider"></li>
        <li class="page-actions__item">
          <button class="page-actions__btn my-templates-btn">Мои шаблоны</button>
        </li>
        <li class="page-actions__item">
          <button class="page-actions__btn save-templates-btn">Сохранить шаблон</button>
        </li>
        <li class="vertical-divider"></li>
        <li class="page-actions__item">
          <button class="page-actions__btn print-report-btn">
            Печать
            <!-- vue-html-to-paper -->
          </button>
        </li>
        <li class="page-actions__item">
          <button class="page-actions__btn download-report-btn">Скачать</button>
        </li>
        <li class="vertical-divider"></li>
        <li class="page-actions__item">
          <button class="page-actions__btn display-params-btn" @click="isOpenDisplaySettings = !isOpenDisplaySettings">
            Параметры отображения
          </button>
        </li>
      </ul>
    </div>

    <div class="container">
      <div class="grid">
        <div class="grid-item grid-item-5">
          <selected-report-data></selected-report-data>
        </div>
        <div class="grid-item grid-item-19">
          <display-setting v-if="isOpenDisplaySettings"></display-setting>

          <div class="report">
            <div v-if="!REPORT.length" class="no-report">
              <h2 class="no-report__title">Не хватает данных для формирования отчета</h2>
            </div>
            <report-view v-if="REPORT.length" :report="REPORT" :reportType="selectedReportType"></report-view>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import SelectedReportData from '../components/SelectedReportData.vue';
import DisplaySettings from '../components/DisplaySettings.vue';
// import HttpService from '@/services/HttpService';
import ReportView from '@/components/Report/ReportView.vue';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Report',
  components: {
    'selected-report-data': SelectedReportData,
    'display-setting': DisplaySettings,
    'report-view': ReportView,
  },

  data() {
    return {
      report: null,
      reportTypes: [
        { id: 1, type: 1, value: 'regular', label: 'Стандартный', isChecked: true },
        { id: 2, type: 1, value: 'trasposed', label: 'Транспонированный', isChecked: false },
        { id: 3, type: 2, value: 'tabular', label: 'Табличный', isChecked: false },
      ],
      selectedReportType: {},
      isOpenDisplaySettings: true,
    };
  },
  computed: {
    ...mapGetters(['dataForReport', 'REPORT_TYPE', 'REPORT']),
  },
  methods: {
    ...mapActions(['GET_REPORT_ELEMENTS', 'SET_REPORT_TYPE', 'GET_REPORT']),

  },
  watch: {
    selectedReportType(type) {
      this.SET_REPORT_TYPE(type)
    }
  },
  created() {
    if(!Object.keys(this.REPORT_TYPE).length) this.selectedReportType = this.reportTypes[0];
    if(Object.keys(this.REPORT_TYPE).length) this.selectedReportType = this.REPORT_TYPE;
  },
};
</script>
<style scoped>
.report {
  padding: 25px 30px;
  background-color: #ffff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
  margin-bottom: 10px;
  height: 100vh;
  overflow: scroll;
}
.no-report {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.no-report__title {
  font-size: 23px;
}

.custom-th {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
  color: #343f55 !important;
  font-weight: 500 !important;
  cursor: auto !important;
  background: #ffff !important;
  border: none !important;
  border-bottom: 1px solid #dcdfe6 !important;
  max-width: 100% !important;
  white-space: nowrap !important;
}
.custom-td {
  border: none !important;
  color: #91a5b2 !important;
}
.period-title {
  font-size: 25px;
  color: #2a7ec4;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #dee8ed;
}
.city-title {
  font-size: 20px;
  color: #34b8e2;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #dee8ed;
}
.specialization-title,
.specialization-sub-title {
  font-weight: 500;
  color: #343f55;
  margin-bottom: 8px;
}
.specialization-sub-title {
  padding-bottom: 8px;
  border-bottom: 1px solid #dee8ed;
}
.position-title,
.company-title {
  color: #343f55;
  font-weight: 600;
  margin-bottom: 8px;
}
.position-description {
  width: 85%;
  font-size: 14px;
  color: #91a5b2;
  margin-bottom: 20px;
}
.tables {
  margin-bottom: 40px !important;
}
.grid-item {
  position: relative;
}
.qualification {
  top: -25px;
  right: 0;
  position: absolute;
  text-align: right;
}
.subordinate-staff-number {
  color: #343f55;
  font-weight: 500;
  font-size: 12px;
}
</style>
