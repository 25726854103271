export const qualifications = [
  {
    orderNumber: 1,
    id: 'hightQualification',
    isTableData: false,
    displayName: 'Высокая квалификация',
    qualCode: 'k3',
  },
  {
    orderNumber: 2,
    id: 'standartQualification',
    isTableData: false,
    displayName: 'Стандартная квалификация',
    qualCode: 'k2',
  },
  {
    orderNumber: 3,
    id: 'elementaryQualification',
    isTableData: false,
    displayName: 'Начальная квалификация',
    qualCode: 'k1',
  },
  {
    orderNumber: 4,
    id: 'allQualifications',
    isTableData: false,
    displayName: 'Все квалификации',
    qualCode: '*,-',
  },
]