export default {
    cities: {
        title: 'Город',
        order: 2,
        nameKey: 'cityName',
        shortNameKey: '',
    },
    regions: {
        nameKey: 'regionName'
    },
    countries: {
        nameKey: 'countryName'
    },
    specializations: {
        title: 'Область специализации',
        order: 3,
        nameKey: 'descriptionText',
        shortNameKey: '',
        parentIdKey: 'mainDescriptionId'
    },
    positions: {
        title: 'Уровень должности',
        order: 4,
        nameKey: 'levelName',
        shortNameKey: 'levelShortName',
    },
    companies: {
        title: 'Группа компаний',
        order: 5,
        nameKey: 'compGroupName',
        shortNameKey: 'compGroupShortName',
    }
}

