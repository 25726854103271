<template>
  <div class="container"> 
  <div id='policy'
    class='parent'
    >
    <p>policy</p>
  </div>
  </div>
</template>

<script>
  // import router from '../router'
  // import HttpService from '@/services/HttpService'

  export default {
    name: 'Policy',
    data: () => ({
      // email:'',
      // password:'',
      // isValid:false,
    }),
    methods: {
      // validate(){
      //   if(this.email ==='' || this.password ==='' ){
      //     this.isValid = true
      //   }

      //   if(this.email !=='' && this.password !=='' ){
      //     this.login()
      //   }
      // },
      // login(){
      //   var self = this
      //   this.$loading(true);
      //   HttpService.methods.post(`/auth`,{
      //       email:this.email,
      //       password:this.password,
      //     })
      //     .then(function (response) {
      //       self.$loading(false);
      //       if(response.data.user){
      //         self.$store.state.user = response.data.user;
      //         localStorage.token = response.data.token;
      //         //localStorage.user = response.data.user;

      //         router.push('/dashboard/missed-calls')
      //       }
      //       if(response.data.error===true){
      //         localStorage.token = '-';
      //         self.isValid = true
      //         alert(response.data.message)
      //       }
      //     })
      //     .catch(function (error) {
      //       localStorage.token = '-';
      //       console.log(error)
      //       alert('Something went wrong')
      //       self.$loading(false);

      //     })
      // },
      // forgotFunc(){
      //   console.log('forgot password')
      // }
    },
    created: function(){
      // console.log("think about redirect to dashboard If token not empty")
    },
  };
</script>

<style scoped lang="less">
  #policy{
   margin: 40px 0;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.18);
    padding: 25px 45px;
  }
  // #login{
  //   background-color: #e5f0f6;
  //   .login-form{
  //     max-width: 322px;
  //   }
  //   .elevation-12{
  //     padding: 20px 20px 20px 20px;
  //     box-shadow: none !important;
  //   }
  //   .login-header{
  //     display: flex !important;
  //     justify-content: center;
  //   }
  //   .v-application p,
  //   .placeholder{
  //     font-family: NunitoSans;
  //     font-size: 12px;
  //     font-weight: bold;
  //     font-stretch: normal;
  //     font-style: normal;
  //     line-height: normal;
  //     letter-spacing: 1px;
  //     color: #7f7f7f;
  //     margin-bottom: 2px;
  //   }
  //   .login-button{
  //     background-color: #2a7ab0;
  //     width: 100%;
  //     font-family: NunitoSans;
  //     font-size: 14px;
  //     color: #ffffff;
  //     text-transform:none ;
  //     border-radius: 0;
  //     height: 44px;
  //   }

  //   v-card-text{
  //     padding: 16px 13px;
  //   }
  //   .v-card__text{
  //     padding: 16px 12px 12px;
  //   }
  //   .v-card__actions{
  //     padding: 8px 16px;

  //   }
  //   .form{
  //     margin: 6px 0 7px;
  //     #email{
  //       margin-bottom: 26px
  //     }
  //     input{
  //       border: 1px solid #e8ecee;
  //       width: 100%;
  //       padding: 10px;
  //     }
  //     .inputWarning{
  //       border-color: red;
  //     }
  //     .buttonDisabled{
  //       pointer-events: none;
  //       cursor: not-allowed;
  //       opacity: 0.65;
  //       filter: alpha(opacity=65);
  //       -webkit-box-shadow: none;
  //       box-shadow: none;
  //     }
  //     .forgot{
  //       font-weight:normal;
  //       cursor: pointer;
  //       letter-spacing: normal
  //     }
  //   }
  // }
</style>
