<template>
  <div class="container">
    <div class="reference">
      <!-- class='parent' -->
      <div class="grid">
        <div class="grid-item grid-item-6">
          <ul>
            <!-- @click="showText($event,item); toggle(item.isOpen)" -->
            <li
              v-for="item in reference"
              @click="showText(item)"
              :key="item.id"
            >
              <!-- v-bind:class="{ 'sidebar-item_active': item.isActive }" -->
              <p
                class="sidebar-item"
                :class="{
                  'sidebar-item_active': item.children
                    ? (item.isActive = false)
                    : item.isActive,
                }"
                @click="toggle(item)"
              >
                {{ item.title }}

                <img
                  src="../assets/images/icon/arrow_down.png"
                  alt=""
                  v-if="item.children"
                />
              </p>

              <ol v-if="item.children" v-show="item.isOpen">
                <!-- @click="toggleSubmenu()" -->
                <li
                  v-for="refItem in item.children"
                  @click="showText(refItem)"
                  :key="refItem.id"
                >
                  <p
                    class="sidebar-item"
                    :class="{ 'sidebar-item_active': refItem.isActive }"
                  >
                    {{ refItem.title }}{{ refItem.isActive }}
                  </p>
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div class="grid-item grid-item-18">
          <div class="text-content parent" v-html="referenceText"></div>
        </div>
        <!-- <div class="col-9 text-content parent" v-html="referenceText"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reference",
  components: {},
  data: () => ({
    // isOpen: false,
    reference: [
      {
        title: "Как найти нужные данные",
        text: `<h1>Справка к отчету SalaryGuide</h1>
                     
              <p>Lorem ipsum dolorem ipsum doloratur. Excepteur sint occaecat cupidatat non
              proident, sunt in cua deserunt mollit aniem ipsum doloratur. Excepteur sint occaecat cupidatat non
              proident, sunt in cua deserunt mollit ani sit amet, consectetur adipisicing elit, sed do eiusmod
              cillum dod est laborum.</p>

              <p>Lorem ipsum doloratur. Excepteur sint occaecat cupidatat non
              proident, sunt in cua deserunt mollit anim id est laborum.</p>

              <p>Lorem ipsum do non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

              <h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</h2>

              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

              <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt <a href="" title="">mollit anim id est   laborum.</a></p>`,
        id: 1,
        isActive: false,
      },
      {
        title: "2как найти нужные данные",
        text: "2бла блабла ",
        id: 2,
        isActive: false,
      },
      {
        title: "3как найти нужные данные",
        text: `<h1>Lofsadfasdfasdfasdfolor sid</h1>
                     
              <p>Lor23423423423dolorem ipsum doloratur. Excepteur sint occaecat cupidatat non
              proident, sunt in cua deserunt mollit aniem ipsum doloratur. Excepteur sint occaecat cupidatat non
              proident, sunt in cua deserunt mollit ani sit amet, consectetur adipisicing elit, sed do eiusmod
              cillum dod est laborum.</p>

              <p>Lorem ipsum doloratur. Excepteur sint occaecat cupidatat non
              proident, sunt 4234234234in cua deserunt mollit anim id est laborum.</p>

              <p>Lorem ipsum do non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

              <h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</h2>

              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

              <h3>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident, sunt in culpa qui officia deserunt <a href="" title="">mollit anim id est   laborum.</a></p>`,
        id: 3,
        isActive: false,
      },
      {
        title: "4как найти нужные данные",
        text: "4бла блабла ",
        id: 4,
        isActive: false,
      },
      {
        title: "5как найти нужные данные",
        id: 5,
        isOpen: false,
        children: [
          {
            title: "6как найти нужные данные",
            text: "6бла блабла ",
            id: 6,
            isActive: false,
          },
          {
            title: "7как найти нужные данные",
            text: "7бла блабла ",
            id: 7,
            isActive: false,
          },
          {
            title: "8как найти нужные данные",
            text: "8бла блабла ",
            id: 8,
            isActive: false,
          },
        ],
      },
      {
        title: "9как найти нужные данные",
        id: 9,
        isOpen: false,
        children: [
          {
            title: "10как найти нужные данные",
            text: "10бла блабла ",
            id: 10,
            isActive: false,
          },
          {
            title: "11как найти нужные данные",
            text: "11бла блабла ",
            id: 11,
            isActive: false,
          },
          {
            title: "12как найти нужные данные",
            text: "12бла блабла ",
            id: 12,
            isActive: true,
          },
        ],
      },
    ],
    referenceText: "",
  }),
  computed: {
    // isFolder: function(item) {
    //   return item.children && item.children.length;
    // }
  },
  methods: {
    toggle: function (item) {
      if (item.children) {
        item.isOpen = !item.isOpen;
      }
    },
    disableActive() {
      console.log(Date());
      this.reference.forEach(function (item) {
        if (item.isActive === true) {
          item.isActive = false;
        }
        if (item.children) {
          let childs = item.children;
          childs.forEach(function (child) {
            if (child.isActive) {
              child.isActive = false;
            }
          });
        }
      });
    },
    showText(item) {
      console.log(item);
      if (item.children) {
        return;
      } else {
        this.disableActive();
      }

      item.isActive = true;
      this.referenceText = "";
      this.referenceText = item.text;
    },
  },
  created: function () {
    this.showText(this.reference[0]);
  },
};
</script>

<style scoped lang="less">
.reference {
  margin: 30px 0;
}
.text-content {
  border: 1px solid #dde2e6;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
  padding: 50px;
  height: 100vh;
}
.reference {
  ul {
    li {
      cursor: pointer;
      margin-bottom: 20px;
    }
    .sidebar-item {
      color: #344058;
      font-size: 18px;
      font-weight: bold;
    }
    .sidebar-item_active {
      color: #2aacdd;
    }
    ol {
      margin-left: 20px;
    }
  }

  .text-content {
    padding: 25px 46px;
    // border-radius: 3px;
    // border: 2px solid #dde7ed;
    // background-color: white;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.18);
    /deep/ h1 {
      font-size: 42px;
      font-weight: bold;
      margin-bottom: 35px;
      color: #343e57;
    }
    /deep/ h2 {
      margin: 29px 0 18px;
      font-size: 33px;
      color: #343e57;
    }
    /deep/ h3 {
      font-size: 18px;
      margin: 3px 0 20px;
      color: #343e57;
    }
    /deep/ p {
      font-size: 19px;
      padding-bottom: 20px;
      line-height: 22px;
      color: #737373;
    }
    /deep/ a {
      color: #45588d;
      text-decoration: none;
    }
  }
}
</style>
