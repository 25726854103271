<template>
  <div class="container">
    <div class="contacts-page">
      <div class="grid">
        <div class="grid-item grid-item-14">
          <h1 class="title">Контакты</h1>
          <p class="description">
            По любым вопросам пишите на почту 
            <a href="mailto:info@amtconsult.ru">info@amtconsult.ru</a>
            или звоните по телефонам
            <a href="tel:+74957885502">+7 (495) 788-5502,</a>
            <a href="tel:+74955850906">+7 (495) 585-0906</a>
          </p>
        </div>
        <div class="grid-item grid-item-10">
          <img src="../assets/images/contacts.png" alt="contacts-img" draggable="false" />
        </div>
      </div>
      <nav class="bottom-nav">
        <router-link class="nav__link" :to="{name:'selectData'}">Выбор данных</router-link>
        <router-link class="nav__link" :to="{name:'report'}">Отчет</router-link>
        <router-link class="nav__link" :to="{name:'settings'}">Настройки</router-link>
        <router-link class="nav__link" :to="{name:'reference'}">Справка</router-link>
        <router-link class="nav__link" :to="{name:'policy'}">PolicyGuide</router-link>
        <button class="nav__link" @click="logout">Выход</button>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from "../router";

export default {
  name: "Contacts",
  methods: {
    ...mapActions(['setLoggedIn']),

    logout() {
      localStorage.removeItem('token');
      this.setLoggedIn(false)
      router.push('/')
    },
  },
};
</script>
<style scoped lang="less">
.contacts-page {
  border: 1px solid #dde2e6;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
  margin-top: 30px;
  padding: 50px 130px;
}
.title {
  font-size: 40px;
  margin-bottom: 80px;
  color: #343f55;
  font-weight: bold;
}
.description {
  color: #666666;
  font-size: 30px;
  line-height: 48px;
}
.description a {
  color: #23abdb;
}
.bottom-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.nav__link {
  color: #93a8b4;
  cursor: pointer;
  text-transform: uppercase;
}
</style>
