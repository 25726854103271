<template>
  <nav class="nav">
    <router-link class="nav__link" :to="{name:'main'}">Главная</router-link>
    <router-link class="nav__link" :to="{name:'selectData'}">Выбор данных</router-link>
    <router-link class="nav__link" :to="{name:'report'}">Отчет</router-link>
    <router-link class="nav__link" :to="{name:'settings'}">Настройки</router-link>
    <router-link class="nav__link" :to="{name:'reference'}">Справка</router-link>
  </nav>
</template>



<script>
import { mapActions } from 'vuex';

export default {
  name: "Navigation",

  methods: {
    ...mapActions(['GET_REPORT_ELEMENTS'])
  },
  created() {
    this.GET_REPORT_ELEMENTS()
  }

};
</script>

<style scoped src="../assets/styles/components/NavigationStyles.css"></style>
