<template>
	<div class="container">
		<div id='main' class='parent'>
			<h1>Main</h1>
		</div>
	</div>
</template>
<script>
// import HttpService from '@/services/HttpService'

export default {
	name: 'Main',
	components: {
		// HeaderComponent,
	},
	data: () => ({
		// email:'',
		// password:'',
		// isValid:false,
	}),
	methods: {

	},
	created: function() {
		// console.log("think about redirect to dashboard If token not empty")
	},
};
</script>
<style scoped lang="less">
#main {
	margin: 40px 0;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.18);
	padding: 25px 45px;
}
</style>