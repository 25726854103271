import { render, staticRenderFns } from "./SelectionSection.vue?vue&type=template&id=5bbf73fe&scoped=true&"
import script from "./SelectionSection.vue?vue&type=script&lang=js&"
export * from "./SelectionSection.vue?vue&type=script&lang=js&"
import style0 from "../../assets/styles/components/SelectionSection.css?vue&type=style&index=0&id=5bbf73fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bbf73fe",
  null
  
)

export default component.exports