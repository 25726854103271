<template>
  <div class="transposed-report-table">
    <div v-for="period in report" :key="period.id">
      <h1 class="period-title" v-for="period in report" :key="period.id">
        {{ periodName }}
      </h1>
      <div v-for="city in period.subItems" :key="city.id">
        <h1 class="city-title">{{ city.data.name }}</h1>

        <div v-for="specialization in city.subItems" :key="specialization.id">
          <h3 class="specialization-title">{{ specialization.data.specTitle }}</h3>
          <h3 class="specialization-sub-title">{{ specialization.data.subSpecTitle }}</h3>

          <div v-for="position in specialization.subItems" :key="position.id">
            <h3 class="position-title">{{ position.data.name }}</h3>
            <p
              v-if="selectedReportSettings.find((setting) => setting.id === 'positionLevels')"
              class="position-description"
            >
              {{ position.data.functions }}
            </p>

            <div v-for="company in position.subItems" :key="company.id">
              <h3 class="company-title">{{ company.data.compGroupName }}</h3>

              <div class="grid tables">
                <div class="grid-item grid-item-12">
                  <div v-if="selectedReportSettings.find((setting) => setting.id === 'company')">
                    <report-table
                      :columns="columns(company.data.company, 'company')"
                      :rows="rows(company.data.company, 'company')"
                    ></report-table>
                  </div>
                </div>

                <div v-for="employee in company.data.employee" :key="employee.qualId" class="grid-item grid-item-12">
                  <div v-if="selectedReportSettings.find((setting) => setting.id === 'employee') && qualCodes.includes(employee.qualCode)">
                    <p class="qualification">qualificationID {{ employee.qualId }} qualCode {{ employee.qualCode }}</p>
                    <report-table
                      :columns="columns(employee.elements, 'employee')"
                      :rows="rows(employee.elements, 'employee')"
                    ></report-table>
                    <p
                      v-if="selectedReportSettings.find((setting) => setting.id === 'employeeNumber')"
                      class="subordinate-staff-number"
                    >
                      Подчинённых сотрудников: {{ employee.subordinateStaffNumber }}
                    </p>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { mapGetters } from 'vuex';
import ReportTable from '@/components/Report/ReportTable.vue';

export default {
  name: 'TransposedReport',
  props: ['report'],
  components: {
    'report-table': ReportTable,
  },
  computed: {
    ...mapGetters(['selectedReportSettings', 'dataForReport']),

    periodName() {
      const periodMonth = this.formatMonth(this.dataForReport.period.periodMonth) 
      return `${periodMonth} ${this.dataForReport.period.periodYear}`
    },
    qualCodes(){
      return this.selectedReportSettings.reduce((codes, setting) => {
        if (setting.qualCode) {
          return [...codes, setting.qualCode]
        }
        return codes
      }, []).join(',')
    }
  },
  methods: {
    columns(data, tableOption) {
      const dataColumnElements = Object.keys(data);
      const columnElements = this.selectedReportSettings
        .filter((setting) => setting.isTableData && dataColumnElements.includes(setting.id))
        .sort((a, b) => a.reportOrderNumber - b.reportOrderNumber);
      const columns = columnElements.reduce(
        (cols, elem) => {
          let columnHeading = elem.displayReportName;
          if (elem.id === 'quantities') {
            columnHeading = elem.displayReportName[tableOption];
          }
          return [
            ...cols,
            {
              label: columnHeading,
              sortable: false,
              field: String(elem.id),
              type: 'number',
            },
          ];
        },
        [
          {
            label: tableOption === 'company' ? 'КОМПАНИИ' : 'СОТРУДНИКИ',
            sortable: false,
            field: '0',
          },
        ]
      );
      return columns
    },
    rows(data) {
      const rows = this.selectedReportSettings
        .filter((setting) => setting.category === 'elements')
        .map((rowElement) => {
        const row = {0: rowElement.displayReportName, orderNumber: rowElement.orderNumber}
        for (const dataColumnKey in data) {
          const dataRowElements = data[dataColumnKey];
          const dataRowElement = dataRowElements.find(dataRowElement => dataRowElement.eId === rowElement.id)
          if (dataRowElement) {
            row[dataColumnKey] = dataRowElement.val
          }
        }
        return row
      });
      return rows.sort((a, b) => a.orderNumber - b.orderNumber)
    },
    formatMonth (value) {
      const monthsLibrary = {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь',
      };
      return monthsLibrary[value];
    },
  },
  created() {
    console.log('TRANSPOSED');
  },
};
</script>

<style scoped>
.period-title {
  font-size: 25px;
  color: #2a7ec4;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #dee8ed;
}
.city-title {
  font-size: 20px;
  color: #34b8e2;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #dee8ed;
}
.specialization-title,
.specialization-sub-title {
  font-weight: 500;
  color: #343f55;
  margin-bottom: 8px;
}
.specialization-sub-title {
  padding-bottom: 8px;
  border-bottom: 1px solid #dee8ed;
}
.position-title,
.company-title {
  color: #343f55;
  font-weight: 600;
  margin-bottom: 8px;
}
.position-description {
  font-size: 14px;
  color: #91a5b2;
  margin-bottom: 20px;
}
.tables {
  margin-bottom: 20px;
}
.grid-item {
  position: relative;
}
.qualification {
  top: -25px;
  right: 0;
  position: absolute;
  text-align: right;
}
.subordinate-staff-number {
  color: #343f55;
  font-weight: 500;
  font-size: 12px;
}
</style>
