export const dataTypes = [
  {
    orderNumber: 1,
    id: 'company',
    isTableData: false,
    displayName: 'Расчет по компаниям',
  },
  {
    orderNumber: 2,
    id: 'employee',
    isTableData: false,
    displayName: 'Расчет по сотрудникам',
  },
]