import { blockVisibility } from "../constants/blockVisibility"
import { metrics } from "../constants/metrics"
import { dataTypes } from "../constants/dataTypes"
import { qualifications } from "../constants/qualifications"

export default {
  isLoggedIn: !!localStorage.getItem('token'),
  dataForReport: {
    period: {},
    cities: [],
    specializations: [],
    positions: [],
    companies: []
  },
  report: [],
  reportType: {},

  reportElements: [],
  reportSettings:{
    metrics,
    dataTypes,
    qualifications,
    blockVisibility,
    elements: [],
  },
  selectedReportSettings: [],
  reportTemplates: [],
  defaultReportTemplate: null,
  
}

