<template>
  <vue-good-table 
    :columns="columns" 
    :rows="rows" 
    :row-style-class="rowStyleClassFn"
  ></vue-good-table>
</template>



<script>
import 'vue-good-table/dist/vue-good-table.css'
export default {
  name: 'ReportTable',
  props: ['rows', 'columns'],

  data() {
    return {
      
    };
  },
  methods: {
    rowStyleClassFn(row) {
      if(row[0] === 'Кол-во компаний' || row[0] === 'Кол-во сотрудников') return 'border-top'
    },
  },
  watch: {
    columns(v) {
      console.log('columns', v);
    },
    rows(v) {
      console.log('rows', v);
    },
  },
};
</script>

<style >
.border-top {
  border-top: 1px solid #dcdfe6;
}
.vgt-inner-wrap {
  border-radius: 0;
  box-shadow: none;
}
table.vgt-table {
  font-size: 12px;
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  border: none;
}
.vgt-table thead th {
  color: #343f55;
  font-weight: 500;
  background: none;
  white-space: nowrap
}
.vgt-table.bordered td, .vgt-table.bordered th {
  border: none; 
}
.vgt-table.bordered th {
  border-bottom: 1px solid #dcdfe6;
}
.vgt-table th {
  padding: 3px 20px 3px 0;
  margin-bottom: 20px;
}
table.vgt-table td {
  padding: 3px 20px 3px 0;
  color: #91a5b2;
}
table.vgt-table td:first-child {
  font-weight: 500;
  color: #343f55;
}
</style>
