<template>
  <div id="registration" class="parent">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="action-block">
            <h1>Регистрация</h1>

            <label for="email">E-mail:</label>
            <div>
              <input
                type="email"
                id="email"
                v-model="email"
                required
                v-bind:class="{ inputWarning: isValid }"
                name="email"
              />
            </div>
            <label>Пароль:</label>
            <div>
              <input
                id="password"
                type="password"
                v-model="password"
                required
                v-bind:class="{ inputWarning: isValid }"
                name="password"
              />
            </div>

            <label>Подтвердите Пароль:</label>
            <div>
              <input
                id="password_confirmation"
                type="password_confirmation"
                v-model="password_confirmation"
                required
                v-bind:class="{ inputWarning: isValid }"
                name="password_confirmation"
              />
            </div>

            <div class="actions">
              <div class="actions-top space-between">
                <a class="link" @click="goTo('')"> Страница логина </a>

                <button class="secondary" @click="validate">Регистрация</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <img src="../../assets/images/login.png" alt="" />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ul class="bottom-menu space-between">
          <li>
            <p>
              По всем вопросам, связанным с работой программы, Вы можете
              свяаться с нами :
            </p>
          </li>
          <li>
            <a class="phone" href="tel:+74967885502">+7 496 788-5502</a>
          </li>
          <li>|</li>
          <li>
            <a class="" href="mailto:survey@amtconult.ru?subject=feedback"
              >survey@amtconult.ru</a
            >
          </li>
          <li>|</li>
          <li>
            <a class="" href="www.salarysurvey.ru">www.salarysurvey.ru</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";
// import HeaderComponent from '@/components/header.vue'
// import HttpService from '@/services/HttpService'

export default {
  name: "Registration",
  components: {
    // HeaderComponent,
  },
  data: () => ({
    email: "",
    password: "",
    isValid: false,
  }),
  methods: {
    goTo(location) {
      router.push(`/${location}`).catch((err) => {
        err;
      });
    },
    validate() {
      if (this.email === "" || this.password === "") {
        this.isValid = true;
      }

      if (this.email !== "" && this.password !== "") {
        this.login();
      }
    },
    login() {
      router.push("/dashboard/missed-calls");
    },
    forgotFunc() {
      console.log("forgot password");
    },
  },
  created: function () {
    console.log("think about redirect to dashboard If token not empty");
  },
};
</script>

<style scoped lang="less">
#registration {
  margin: 60px 0;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.18);
  .action-block {
    margin: 36px 0 0 23%;
    h1 {
      font-size: 45px;
      margin-bottom: 71px;
      color: #343f55;
    }
    label {
      display: block;
      margin-bottom: 5px;
      color: #646464;
    }
    input {
      height: 45px;
      max-width: 340px;
      width: 100%;
      margin-bottom: 30px;
    }
    .actions {
      width: 340px;
      margin-top: 23px;
      .actions-top {
        margin-bottom: 20px;
      }
      .actions-bottom {
        .link {
          float: right;
          margin-right: 10px;
        }
      }
    }
  }
  img {
    width: 100%;
    padding: 0 76px;
  }
  .bottom-menu {
    margin: 0 12%;
    padding: 22px 0;
    border-top: 1px solid #f0f4f7;
    li {
      color: #8ba7bd;
      a {
        color: #8ba7bd;
      }
    }
  }
}
</style>




