<template>
  <div>
    <div class="sorting">
      <span>Сортировать по:</span>
      <select class="select" v-model="selectedSortBy">
        <option>Период сбора данных</option>
        <option>Пункт 2</option>
        <option>Пункт 3</option>
      </select>
    </div>
    <span>Выбрано: {{ selectedSortBy }}</span>

  </div>
</template>



<script>
export default {
  name: 'TubularReport',

  data() {
    return {
      selectedSortBy: 'Период сбора данных'
    }
  }

}
</script>

<style scoped>
.select {
  padding: 10px 15px;
  color: red;
  background: transparent;
}

</style>