<template>
  <div class="container"> 
    <div class='proposals'>
      <h1>Proposals</h1>
    </div>
  </div>
</template>

<script>
  // import HttpService from '@/services/HttpService'

  export default {
    name: 'Proposals',
    data: () => ({
    }),
    methods: {
    
    },
    created: function(){
      // console.log("think about redirect to dashboard If token not empty")
    },
  };
</script>

<style scoped lang="less">
  .proposals {
    border: 1px solid #dde2e6;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
    margin: 30px 0;
    padding: 50px 130px;
  }
</style>
