export default {
  dataForReport: (state) => {
    return state.dataForReport
  },
  isLoggedIn: (state) => {
    return state.isLoggedIn
  },
  reportElements: (state) => {
    return state.reportElements
  },
  reportSettings: (state) => {
    return state.reportSettings
  },
  selectedReportSettings: (state) => {
    return state.selectedReportSettings
  },
  defaultReportTemplate: (state) => {
    return state.defaultReportTemplate
  },
  REPORT: (state) => {
    return state.report
  },
  REPORT_TYPE: (state) => {
    return state.reportType
  }
}