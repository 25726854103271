import axios from 'axios';
import router from '../router';



axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      router.push(`/`);
    }
    console.error(error.response.status, error.response.statusText);
    return Promise.reject(error);
  }
);

export default {
  name: 'HttpService',
  props: {},
  data: () => ({}),
  methods: {
    post(url, data) {
      let headers = {
        headers: {
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('token'),
        },
      };
      return axios.post(url, data, headers);
    },
    unauthPost(url, data) {
      let headers = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      return axios.post(url, data, headers);
    },
    get(url, params) {
      return axios.get(url, {
        params,
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('token'),
        },
      });
    },

    delete(url) {
      return axios.delete(url, {
        headers: {
          // 'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          authorization: localStorage.getItem('token'),
        },
      });
    },
  },
};

// postFiles (url, data) {
//   var setHeaders = store.state.headers
//   setHeaders['headers']['Content-Type'] = 'multipart/form-data'
//   const url = store.state.basicUrl + url

//   return axios.post(
//     url,
//     data,
//     setHeaders
//   )
// },
// put (url, data) {
//   // const setHeaders = store.state.headers
//   // const url = store.state.basicUrl + url
//   return axios.put(
//     url,
//     data,
//     // setHeaders
//   )
// },
