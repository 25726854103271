<template>
  <div class="select-data-page">
    <div class="page-actions">
      <ul class="page-actions__list">
        <li class="page-actions__item">
          <span>Открыт шаблон: Типовой</span>
        </li>
        <li class="vertical-divider"></li>
        <li class="page-actions__item">
          <button class="page-actions__btn my-templates-btn">Мои шаблоны</button>
        </li>
        <li class="page-actions__item">
          <button class="page-actions__btn save-templates-btn">Сохранить шаблон</button>
        </li>
      </ul>
    </div>

    <div class="container">
      <div class="select-data">
        <section class="section">
          <div class="section__top">
            <div class="grid">
              <div class="grid-item grid-item-6">
                <h2 class="section-title">
                  <span class="section-title__number">1.</span>
                  Период
                </h2>
              </div>
              <div class="grid-item grid-item-6">
                <div class="grid">
                  <div class="grid-item grid-item-12" v-for="period in periods" :key="period.id">
                    <label class="custom-radiobutton">
                      <input
                        :value="period.id"
                        :checked="period.id === dataForReport.period.id"
                        @change="changePeriod(period, $event)"
                        type="radio"
                      />
                      <span>
                        {{ formatMonth(period.periodMonth) }}
                        {{ period.periodYear }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="grid-item grid-item-12 saved-data-wrap">         
                <div class="saved-data">
                  <span v-if="!dataForReport.period.id" class="not-saved-data__title">
                    Выбор не сделан
                  </span>
                  <span v-if="dataForReport.period.id" class="saved-data__count">
                    Выбрано
                    {{ dataForReport.period.id ? '1' : '' }}:&nbsp;</span
                  >
                  <span v-if="dataForReport.period" class="saved-data__list">
                    {{ formatMonth(dataForReport.period.periodMonth) }}
                    {{ dataForReport.period.periodYear }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <select-data-option
          v-for="type of dataTypes"
          :key="type.typeName"
          :type="type.typeName" 
          :data="type.data"
        ></select-data-option>

        <div class="greate-report">
          <button 
            class="primary-btn"
            :class="[isDisabled ? 'disabled' : null]"
            @click="createReport"
          >
            Сформировать отчёт
          </button>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import HttpService from '@/services/HttpService';
import { mapActions, mapGetters } from 'vuex';
import router from '../router';
import SelectDataOption from '@/components/SelectData/SelectDataOption.vue';

export default {
  name: 'SelectData',
  components: {
    'select-data-option': SelectDataOption,
  },
  data() {
    return {
      isDisabled: true,

      dataTypes: {
        cities: {
          typeName: 'cities',
          data: {
            cities: [],
            regions: [],
            countries: [],
          }
        },
        specializations: {
          typeName: 'specializations',
          data: []
        },
        positions: {
          typeName: 'positions',
          data: []
        },
        companies: {
          typeName: 'companies',
          data: []
        }
      },
      periods: [],
      selectedPeriod: {},
    };
  },
  computed: {
    ...mapGetters(['dataForReport']),
  },
  methods: {
    ...mapActions(['setDataForReport', 'GET_REPORT', 'REMOVE_DATA_FOR_REPORT']),
    
    changePeriod(period, event) {
      if (this.selectedPeriod.id && period.id !== this.selectedPeriod.id) {
        const agree = confirm('Выбранные данные будут потеряны. Вы уверены?');
        if (!agree) {
          event.target.checked = false;
          return;
        }
      }
      
      
      this.setDataForReport({
          period: {},
          cities: [],
          specializations: [],
          positions: [],
          companies: []
      });

      this.setDataForReport({ key: 'period', data: period });
      this.selectedPeriod = period;
    },

    serializeSpecializations(specializations) {
      const parents = specializations.filter((specialization) => !specialization.mainDescriptionId);
      parents.forEach((parentSpecialization) => {
        parentSpecialization.children = specializations.filter(
          (specialization) => specialization.mainDescriptionId === parentSpecialization.id
        );
      });
      return parents;
    },
    formatMonth(value) {
      const monthsLibrary = {
        1: 'Январь',
        2: 'Февраль',
        3: 'Март',
        4: 'Апрель',
        5: 'Май',
        6: 'Июнь',
        7: 'Июль',
        8: 'Август',
        9: 'Сентябрь',
        10: 'Октябрь',
        11: 'Ноябрь',
        12: 'Декабрь',
      };
      return monthsLibrary[value];
    },

    getPeriods() {
      // periods?expand=id:qualification,id:currencyRate,id:taxRate&id=641
      HttpService.methods
        .get(`/api/periods?expand=id:qualification,id:currencyRate,id:taxRate`, {})
        .then((response) => {
          this.periods = response.data.period.data;
        });
    },

    async getData() {
      const citiesParams = {
        periodId: this.selectedPeriod.id,
        // periodId: 643,
        // regionId: this.selectedRegion?.id,
        sortBy: 'cityName',
        offset: 0,
      };
      const reqs = [
        HttpService.methods.get(`/api/countries`, { periodId: this.selectedPeriod.id }),
        HttpService.methods.get(`/api/regions`, { periodId: this.selectedPeriod.id }),
        HttpService.methods.get(`/api/cities`, citiesParams),
        HttpService.methods.get(`/api/specialization-descriptions`, { periodId: this.selectedPeriod.id }),
        HttpService.methods.get(`/api/position-levels`, { periodId: this.selectedPeriod.id }),
        HttpService.methods.get(`/api/company-groups`, { periodId: this.selectedPeriod.id })
      ];
      
      try {
        const [
          rawCountries, 
          rawRegions, 
          rawCities, 
          rawSpecializations, 
          rawPositions, 
          rawCompanies
        ] = await Promise.all(reqs);

        this.dataTypes.cities.data.cities = rawCities.data.city.data
        this.dataTypes.cities.data.regions = rawRegions.data.region.data
        this.dataTypes.cities.data.countries = rawCountries.data.country.data

        this.dataTypes.specializations.data = this.serializeSpecializations(rawSpecializations.data.specializationDescription.data)
        this.dataTypes.positions.data = rawPositions.data.positionLevel.data
        this.dataTypes.companies.data = rawCompanies.data.companyGroup.data
      } 
      catch(e) {
        console.log(e)
      }
      console.log('DATA', this.dataTypes)

      // const regionsData = rawRegions.data.region.data.map((region) => {
      //   return { ...region, children: rawCities.data.city.data.filter((city) => city.regionId === region.id) };
      // });
      // const citiesData = rawCountries.data.country.data.map((country) => {
      //   return { ...country, children: regionsData.filter((region) => region.countryId === country.id) };
      // });

      // this.citiesData = citiesData

    },
    createReport() {
      this.GET_REPORT()
      router.push('/report');
    },
  },
  created() {
    this.getPeriods();
  },
  watch: {
    dataForReport: {
      handler(v) {
        const isFullData = Object.values(v).every(value => {
          return (Array.isArray(value) && value.length) || (!Array.isArray(value) && value.id)
        })
        if(isFullData) {
          this.isDisabled = false
        } else {
          this.isDisabled = true
        }
      },
      immediate: true
    },

    selectedPeriod: {
      handler() {
        if (this.dataForReport.period.id) {
          this.getData();
        }
      },
      immediate: true
    },

  },
};
</script>

<style scoped src="../assets/styles/components/SelectData.css"></style>
