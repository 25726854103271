<template>
  <div class="display-settings">
    <h2 class="settings-title">Параметры отображения</h2>
    <div class="grid">
      <div class="grid-item grid-item-12">
        <div class="grid">
          <div class="grid-item grid-item-12">
            <h2 class="settings-category__title">Показатели</h2>
            <div v-for="setting in reportSettings.metrics" :key="setting.id">
              <label class="custom-checkbox">
                <input type="checkbox" :value="setting" v-model="selectedSettings" />
                <span>{{ setting.displayName }}</span>
              </label>
            </div>
          </div>
          <div class="grid-item grid-item-12">
            <h2 class="settings-category__title">Отображать</h2>
            <div v-for="setting in reportSettings.blockVisibility" :key="setting.id">
              <label class="custom-checkbox">
                <input type="checkbox" :value="setting" v-model="selectedSettings" />
                <span>{{ setting.displayName }}</span>
              </label>
            </div>
          </div>
          <div class="grid-item grid-item-12">
            <h2 class="settings-category__title">Уровни квалификаций</h2>
            <div v-for="setting in reportSettings.qualifications" :key="setting.id">
              <label class="custom-checkbox">
                <input type="checkbox" :value="setting" v-model="selectedSettings" />
                <span>{{ setting.displayName }}</span>
              </label>
            </div>
          </div>
          <div class="grid-item grid-item-12">
            <h2 class="settings-category__title">Способы расчета</h2>
            <div v-for="setting in reportSettings.dataTypes" :key="setting.id">
              <label class="custom-checkbox">
                <input type="checkbox" :value="setting" v-model="selectedSettings" />
                <span>{{ setting.displayName }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-item grid-item-12">
        <h2 class="settings-category__title">Структура оплаты</h2>
        <div class="cols-2">
          <div v-for="setting in reportSettings.elements" :key="setting.id">
            <label class="custom-checkbox">
              <input type="checkbox" :value="setting" v-model="selectedSettings" />
              <span>{{ setting.displayName }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DisplaySettings',
  data() {
    return {
      selectedSettings: [],
    };
  },
  computed: {
    ...mapGetters(['selectedReportSettings','reportSettings','defaultReportTemplate']),
  },
  methods: {
    ...mapActions(['SET_SELECTED_REPORT_SETTINGS']),
  },
  watch: {
    selectedSettings(v) {
      console.log('tableData', v.filter(e => e.isTableData).map(e => e.id));
      this.SET_SELECTED_REPORT_SETTINGS(v);
    },
    defaultReportTemplate(v){
      if (v) {
        this.selectedSettings = this.selectedReportSettings
      }
    }
  },
  created() {
    if (this.defaultReportTemplate) {
      this.selectedSettings = this.selectedReportSettings
    }
  },
};
</script>
<style scoped>
.cols-2 {
  columns: 2;
}
.display-settings {
  border: 1px solid #dde2e6;
  border-radius: 5px;
  background-color: #f2fcfe;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.18);
  padding: 25px;
  margin-bottom: 15px;
}
.settings-title {
  color: #405a91;
  font-size: 24px;
  font-weight: 500;
}
.settings-category__title {
  color: #405a91;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 0 10px 0;
  padding-top: 10px;
  border-top: 1px solid #dee8ed;
}
</style>
