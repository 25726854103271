<template>
  <div id="app">
    <header-component />
    <navigation-component v-if="isLoggedIn"></navigation-component>
    <main>
      <router-view />
    </main>
  </div>
</template>




<script>
import Header from '@/components/HeaderComponent.vue';
import Navigation from '@/components/NavigationComponent.vue';
// import router from "@/router";
// import { eventBus } from "./main.js";
import { mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    'header-component': Header,
    'navigation-component': Navigation,
  },
  computed: {
    ...mapGetters(['isLoggedIn']),

  },
};
</script>

<style>
@import 'assets/styles/main.css';
</style>
