<template>
    <div class="container">
      <section class="section-login">
        <div class="grid">
          <div class="grid-item grid-item-14">
            <h1 class="section-title">Приветствуем вас на портале зарплат АМТ Консалт</h1>
            <form 
              @submit.prevent="login"
              class="form"
            >
              <div class="form-group">
                <label>
                  E-mail:
                  <input
                    v-model="email"
                    name="email"
                    required
                  >
                </label>
                <label>
                  Пароль:
                  <input 
                    v-model="password"
                    name="password"
                    required
                  >
                </label>
              </div>
              <div class="form-group">
                <a class="link" href="#">Получить доступ</a>
                <button type="submit" class="secondary-btn">Войти</button>
              </div>
              <div class="form-group">
                <a class="link" href="#">Забыли пароль</a>
              </div>
            </form>
          </div>
          <div class="grid-item grid-item-10">
            <img src="../../assets/images/login.png" alt="login-img" draggable="false" />
          </div>
        </div>
        <div class="info">
          <span>По всем вопросам, связанным с работой программы, Вы можете свяаться с нами:</span>
          <a href="tel:+74967885502">+7 496 788-5502</a>
          <a href="mailto:survey@amtconult.ru">survey@amtconult.ru</a>
          <a href="https://salarysurvey.ru/" target="_blank">www.salarysurvey.ru</a>
        </div>
      </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from "@/router";
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
// import ErrorService from '@/services/ErrorService'
// import HeaderComponent from '@/components/header.vue'
// import axios from "axios";
import HttpService from "@/services/HttpService";

export default {
  name: "Login",
  components: {
    // PulseLoader
    // HeaderComponent,
  },
  data: () => ({
    email: "oleg",
    password: "87NN292kX",
    // email:'',
    // password:'',
    isValid: false,
  }),
  methods: {
    ...mapActions(['setLoggedIn']),

    login() {
      HttpService.methods
        .unauthPost(
          `/api/access-tokens?grant_type=password&client_id=7aa8f65e-36dd-11eb-9772-00099cd007c2&username=${this.email}&password=${this.password}`,
          {}
        )
        .then((response) => {
          if (response) {
            console.log("response USER", response);
          }
          if (response.data) {
            localStorage.token = `${response.data.token_type} ${response.data.access_token}`;

            this.setLoggedIn(true)

            router.push("/main");
          }
        })
        .catch((error) => {
          console.log(error.response);
          alert(
            `Чтото пошло не так: ${error.response.status} ${error.response.statusText} `
          );
        });

      // HttpService.methods
      //   .post(`/api/access-tokens`, {
      //     client_id: "7aa8f65e-36dd-11eb-9772-00099cd007c2",
      //     grant_type: "password",
      //     // email: "oleg",
      //     // password: "87NN292kX",

      //     email:this.email,
      //     password:this.password,
      //   })
      //   .then((response) => {
      //     if (response) {
      //       console.log("response", response);
      //     }
      //     if (response.data) {
      //       console.log("response.data", response.data);
      //       // self.$store.state.user = response.data.user;
      //       // localStorage.token = response.data.token;
      //       //localStorage.user = response.data.user;
      //       // router.push('/dashboard/main')
      //     }
      //     if (response.data.error === true) {
      //       console.log("response.data.error", response.data.error);
      //       // localStorage.token = '-';
      //       // self.isValid = true
      //       // alert(response.data.message)
      //     }
      //   });
      //   .catch( (error) => {
      //     ErrorService.methods.showError(error,'Login(login)')

      //     // if (error) {
      //     //   console.log('error',error)
      //     // }

      //     // if (error.config) {
      //     //   console.log('error.config',error.config);
      //     // }

      //     // if (error.response) {
      //     //   console.log('error.response',error.response);
      //     // }

      //     // if (error.request) {
      //     //   console.log('error.request',error.request);
      //     // }

      //     // if (error.message) {
      //     //   console.log('error.message',error.message);
      //     // }

      //     // // Error 😨
      //     // if (error.response) {

      //     //      * The request was made and the server responded with a
      //     //      * status code that falls out of the range of 2xx

      //     //     console.log('😨',error.response.data);
      //     //     console.log('😨',error.response.status);
      //     //     console.log('😨',error.response.headers);
      //     // } else if (error.request) {
      //     //     /*
      //     //      * The request was made but no response was received, `error.request`
      //     //      * is an instance of XMLHttpRequest in the browser and an instance
      //     //      * of http.ClientRequest in Node.js
      //     //      */
      //     //     console.log('😨',error.request);
      //     // } else {
      //     //     // Something happened in setting up the request and triggered an Error
      //     //     console.log('Error😨', error.message);
      //     // }

      //   })
    },

  },
};
</script>

<style scoped lang="less">
.section-login {
  border: 1px solid #dde2e6;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.18);
  margin: 30px 0;
  padding: 50px 130px;
}

.section-title {
  font-size: 40px;
  margin-bottom: 65px;
  color: #343f55;
  font-weight: bold;
  line-height: 1.2;
}
.form {
  width: 340px;
}
.form-group {
  margin-bottom: 20px;
}
label {
  color: #646464;
}
input {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #ffffff;
  padding: 10px;
  outline: none;
  border: 2px solid #dee8ed;
  border-radius: 5px;
}
.form-group:nth-child(2) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.form-group:nth-child(3) {
  display: flex;
  justify-content: flex-end;
}

.secondary-btn {
  padding: 15px 55px;
}

.info {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 60px;
  font-size: 14px;
  color: #8ba7bd;
  border-top: 1px solid #dee8ed;
}

</style>


