<template>
  <div class="data-selecting">
    <h4 v-if="data.length === 0">Не найдено!</h4>

    <ul v-if="type !== 'cities'">
      <li v-for="item in data" :key="item.id">
        <label v-if="!item.children" class="custom-checkbox">
          <input type="checkbox" v-model="selectedData" :value="item" />
          <span
            >{{ item[content.nameKey] }} {{ item[content.shortNameKey] && '(' + item[content.shortNameKey] + ')' }}</span
          >
        </label>
        <label v-if="item.children" class="custom-checkbox">
          <input type="checkbox" v-model="selectedData" @change="selectItem(item)" :value="item" />
          <span class="link" @click.prevent="setOpenParent(item.id)">
            {{ item[content.nameKey] }}
          </span>
        </label>
        <ul v-if="item.children && openParent == item.id" class="children-list">
          <li  v-for="childItem in item.children" :key="childItem.id">
            <label class="custom-checkbox">
              <input type="checkbox" v-model="selectedData" @change="selectItem(childItem)" :value="childItem" />
              <span>
                {{
                  childItem[content.nameKey]
                    .split(' ')
                    .slice(0, -1)
                    .join(' ')
                }}
                <span>
                  {{
                    childItem[content.nameKey]
                      .split(' ')
                      .slice(-1)
                      .join('')
                  }}
                  &nbsp;
                  <i
                    v-if="childItem.functions"
                    class="tooltip-icon"
                    v-tooltip.right="{ content: childItem.functions, offset: 20, boundariesElement: 'body' }"
                    >?</i
                  >
                </span>
              </span>
            </label>

          </li>
        </ul>
      </li>
    </ul>

    <!-- <template v-if="type === 'cities'">
        <h3 v-if="citiesSelectType == 'alphabet'" class="selecting__title">
          Все города
        </h3>
        <h3 v-if="citiesSelectType == 'regions' && !selectedRegion" class="selecting__title">
          Области
        </h3>
        <h3 v-if="citiesSelectType == 'regions' && selectedRegion" class="selecting__title">
          Города
        </h3>
        <div v-if="citiesSelectType === 'alphabet'">
          <h4 v-if="citiesByAlphabet.length === 0">Города не найдены!</h4>
          <div v-for="(letter, i) in citiesAlphabet" :key="letter" class="grid">
            <div class="grid-item grid-item-2">
              <span class="city-letter">{{ letter }}</span>
            </div>
            <div class="grid-item grid-item-22">
              <ul class="grid">
                <li v-for="city in citiesByAlphabet[i]" :key="city.id" class="grid-item grid-item-12">
                  <label class="custom-checkbox">
                    <input
                      type="checkbox"
                      v-model="selectedData"
                      @change="(e) => changeCity(city)"
                      :value="city"
                    />
                    <span>{{ city.cityName }}</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="citiesSelectType === 'regions'">
          <div class="grid" v-for="letter in regionsAlphabet" :key="letter">
            <div class="grid-item grid-item-2">
              <span>{{ letter }}</span>
            </div>
            <div class="grid-item grid-item-22">
              <ul class="grid">
                <li class="grid-item grid-item-12" v-for="region in data.regions" :key="region.id">
                  <label class="custom-checkbox">
                    <input
                      type="checkbox"
                      v-model="selectedRegions"
                      @change="(e) => changeRegion(region)"
                      :value="region"
                    />
                    <span class="link" @click="selectedRegion = region">{{ region.regionName }}</span>
                    <span v-if="region.partial">(partial)</span>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>

    </template> -->
  </div>
</template>



<script>
export default {
  name: 'CheckboxList',
  props: ['data', 'selected', 'content', 'changeSelected', 'type'],
  data() {
    return {
      selectedData: [],
      openParent: null,
      citiesSelectType: 'alphabet',
      selectedLetter: 'М',

      selectedCities: [],
      selectedRegions: [],
      selectedRegion: null,
      selectedCountries: [],
    };
  },
  computed: {
    // regionsAlphabet() {
    //   return this.getAlphabet(this.data.regions, 'regionName');
    // },
    // citiesFullAlphabet() {
    //   const cities = this.data.cities;
    //   const alphabet = this.getAlphabet(cities, 'cityName')
    //   console.log('citiesFullAlphabet', alphabet)
      
    //   return this.getAlphabet(cities, 'cityName');
    // },
    // citiesAlphabet() {
    //   const alphabet = this.citiesFullAlphabet

    //   if (this.selectedLetter) {
    //     return alphabet.filter((letter) => letter === this.selectedLetter);
    //   } else {
    //     return alphabet;
    //   }
    // },
    // citiesByAlphabet() {
    //   const cities = this.data.cities;
    //   console.log('this.data.cities', this.data.cities)

    //   const alphabet = this.citiesAlphabet.map((letter) =>
    //     cities.filter((city) => city.cityName.slice(0, 1).toUpperCase() === letter)
    //   );
    //   console.log('citiesByAlphabet', alphabet)

    //   return this.citiesAlphabet.map((letter) =>
    //     cities.filter((city) => city.cityName.slice(0, 1).toUpperCase() === letter)
    //   );
    // },

  },
  methods: {
    // getAlphabet(arr, key) {
    //   return [...new Set(arr.map((item) => item[key].slice(0, 1).toUpperCase()))].sort();
    // },
    // changeCountry(selectedCountry) {
    //   this.selectRegionsByCountry(selectedCountry);
    // },
    // changeRegion(selectedRegion) {
    //   this.selectCitiesByRegion(selectedRegion);

    //   if (this.selectedRegions.length === 0) {
    //     this.selectedCountries = [];
    //     return;
    //   }
    //   const allRegionsWithSameCountryId = this.data.regions.filter((region) => region.countryId === selectedRegion.countryId).length;

    //   const allSelectedRegionsWithSameCountryId = this.selectedRegions.filter(
    //     (region) => region.countryId === selectedRegion.countryId
    //   ).length;

    //   const targetCountry = this.data.countries.find((country) => country.id === selectedRegion.countryId);

    //   if (allRegionsWithSameCountryId === allSelectedRegionsWithSameCountryId) {
    //     this.selectedCountries.push(targetCountry);
    //   }
    // },
    // changeCity(selectedCity) {
    //   console.log('CHANGE SELECTED CITIES', this.selectedCities);
    //   if (this.selectedCities.length === 0) {
    //     this.selectedRegions = [];

    //     this.selectedCountries = [];
    //     return;
    //   }

    //   const allCitiesWithSameRegionId = this.data.cities.filter((city) => city.regionId === selectedCity.regionId).length;
    //   const allSelectedCitiesWithSameRegionId = this.selectedCities.filter(
    //     (city) => city.regionId === selectedCity.regionId
    //   ).length;
    //   const targetRegion = this.data.regions.find((region) => region.id === selectedCity.regionId);
    //   if (allCitiesWithSameRegionId === allSelectedCitiesWithSameRegionId) {
    //     this.selectedRegions.push(targetRegion);
    //     // this.changeRegion(targetRegion);
    //   }
    // },
    // selectCitiesByRegion(selectedRegion) {
    //   const exist = this.selectedRegions.find((region) => region.id === selectedRegion.id);
    //   if (exist) {
    //     this.data.cities.forEach((city) => {
    //       if (city.regionId === selectedRegion.id) {
    //         const cityIdx = this.selectedCities.findIndex((selectedCity) => selectedCity.id === city.id);
    //         if (cityIdx === -1) {
    //           this.selectedCities.push(city);
    //         }
    //       }
    //     });
    //   } else {
    //     this.selectedCities = this.selectedCities.filter((selectedCity) => selectedCity.regionId !== selectedRegion.id);
    //   }
    // },
    // selectRegionsByCountry(selectedCountry) {
    //   const exist = this.selectedCountries.find((country) => country.id === selectedCountry.id);
    //   if (exist) {
    //     this.data.regions.forEach((region) => {
    //       if (region.countryId === selectedCountry.id) {
    //         const regionIdx = this.selectedRegions.findIndex((selectedRegion) => selectedRegion.id === region.id);
    //         if (regionIdx === -1) {
    //           this.selectedRegions.push(region);
    //         }
    //       }
    //     });
    //   } else {
    //     this.selectedRegions = this.selectedRegions.filter(
    //       (selectedRegion) => selectedRegion.countryId !== selectedCountry.id
    //     );
    //   }
    //   this.data.regions.forEach((region) => {
    //     this.selectCitiesByRegion(region);
    //   });
    // },




    setOpenParent(id) {
      if (this.openParent === id) {
        this.openParent = null;
      } else {
        this.openParent = id;
      }
    },
    selectItem(item) {
      if (item.children) {
        const exist = this.selectedData.find((selectedItem) => selectedItem.id === item.id);
        if (exist) {
          item.children.forEach((childItem) => {
            const exist = this.selectedData.find((selectedItem) => selectedItem.id === childItem.id);
            if (!exist) {
              this.selectedData.push(childItem);
            }
          });
        } else {
          this.selectedData = this.selectedData.filter(
            (selectedItem) => selectedItem[this.content.parentIdKey] !== item.id
          );
        }
      }
    },
  },
  created(){
    if (this.type) {
      console.log('!!!!!!!!!!>>', this.data);
    }
  },
  watch: {
    selected: {
      handler(v) {
        this.selectedData = v
      },
      immediate: true    
    },
    selectedData(v) {
      this.changeSelected(v);
    },

    // citiesFullAlphabet(v) {
    //   if (!this.selectedRegion) {
    //     this.selectedLetter = v[0];
    //   } else {
    //     this.selectedLetter = null;
    //   }
    // },
    // selectedRegion(v) {
    //   // this.getCities();
    //   if (v) {
    //     this.citiesSelectType = 'region';
    //   }
    // },

    // citiesSelectType(v) {
    //   if (v === 'regions') {
    //     this.selectedRegion = null;
    //   }
    //   if (v === 'alphabet') {
    //     // this.getCities();
    //     this.selectedRegion = null;
    //   }
    // },
  },
};
</script>

<style scoped src='../../assets/styles/components/CheckboxList.css'></style>
