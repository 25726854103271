export default {
  setDataForReport: (state, payload) => {
    
    if (payload?.key) {
      state.dataForReport = {...state.dataForReport, [payload.key]: payload.data}
    } else{
      state.dataForReport = payload
    }
    console.log('STORE setDataForReport', state.dataForReport);
  },
  setLoggedIn: (state, payload) => {
    state.isLoggedIn = payload
    console.log('mutation setLoggedIn', state.isLoggedIn)
  },

  setReportElements: (state, payload) => {
    state.reportElements = payload
    console.log('mutation reportElements', state.reportElements)
  },
  setReportElementsToSettings: (state, payload) => {
    state.reportSettings.elements = payload
    console.log('mutation reportSettings', state.reportSettings)
  },
  setReportTemplates: (state, payload) => {
    state.reportTemplates = payload
    console.log('mutation reportTemplates',  state.reportTemplates)
  },
  setReportDefaultTemplate: (state, payload) => {
    state.defaultReportTemplate = payload
    console.log('mutation defaultReportTemplate', state.defaultReportTemplate)
  },
  setSelectedReportSettings: (state, payload) => {
    state.selectedReportSettings = payload
    console.log('mutation selectedReportSettings', state.selectedReportSettings)
  },

  SET_REPORT: (state, payload) => {
    state.report = payload
    console.log('mutation REPORT', state.report)
  },

  SET_REPORT_TYPE: (state, payload) => {
    state.reportType = payload
  },

  REMOVE_DATA_FOR_REPORT: (state, payload) => {
    state.dataForReport = payload
  }
  
}

