<template>
    <form class="search" @submit.prevent="submitForm">
      <input 
        v-model="searchQuery" 
        class="search__input"
        type="search"
        name="search" 
      />
      <button class="secondary-btn" type="submit">Поиск</button>
    </form>
</template>



<script>
export default {
  name: 'Search',
  props: ['handleSearch'],

  data() {
    return {
      searchQuery: ''

    }
  },
  methods: {
    submitForm() {
      this.handleSearch(this.searchQuery)
    }
  }
  

}
</script>

<style scoped src='../../assets/styles/components/Search.css'></style>