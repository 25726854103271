export const metrics = [
  {
    orderNumber: 1,
    reportOrderNumber: 1,
    id: '90pct',
    isTableData: true,
    displayName: '90% (9-й децинтиль)',
    displayReportName: '90%',
  },
  {
    orderNumber: 2,
    reportOrderNumber: 2,
    id: '75pct',
    isTableData: true,
    displayName: '75% (3-й квартиль)',
    displayReportName: '75%',
  },
  {
    orderNumber: 3,
    reportOrderNumber: 3,
    id: '50pct',
    isTableData: true,
    displayName: '50% (медиана)',
    displayReportName: '50%',
  },
  {
    orderNumber: 4,
    reportOrderNumber: 4,
    id: '25pct',
    isTableData: true,
    displayName: '25% (1-й квартиль)',
    displayReportName: '25%',
  },
  {
    orderNumber: 5,
    reportOrderNumber: 5,
    id: '10pct',
    isTableData: true,
    displayName: '10% (1-й дециль)',
    displayReportName: '10%',
  },
  {
    orderNumber: 6,
    reportOrderNumber: 5,
    id: 'mean',
    isTableData: true,
    displayName: 'Среднеe арифметическое',
    displayReportName: 'Среднеe',
  },
];
